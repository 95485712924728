import React, { useEffect, useState } from "react";
import {
  Card,
  TextInput,
  Textarea,
  Button,
  Text,
  Divider,
  Switch,
  MultiSelect,
  Select,
} from "@mantine/core";
import toast from "react-hot-toast";
import axios from "axios";

import { Taggit } from "@components/Tag";
import { tableCategories } from "./helpers";

export default function ReportRecipeEdit({ id, data, fetchData }) {
  const [state, setState] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (data) {
      setState(data);
    }
  }, [JSON.stringify(data)]);

  function onSubmit() {
    setLoading(true);

    const req = {
      ...state,
      table_categories: state.table_categories.map((m) => parseInt(m)),
      report_recipe_id: id,
    };

    axios
      .put(`/report-recipes/${id}/`, req)
      .then(() => {
        setLoading(false);
        toast.success("Updated!");
        fetchData();
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  function onQcChange(qc, editedObj) {
    const newState = { ...state };
    const newQc = [...newState.query_components];
    let newObj = newQc.find((f) => f.server_name === qc.server_name);
    if (!newObj) return;
    newObj[editedObj.key_to_update] = editedObj.value;
    setState({
      ...state,
      query_components: newQc,
    });
  }

  function qcValidated() {
    if (!state.query_components) return true;
    if (state.query_components.find((f) => !f.label)) {
      return false;
    } else {
      return true;
    }
  }

  const validated =
    state.title &&
    state.description &&
    qcValidated() &&
    state.table_categories.length > 0;

  const tableCategoryOptions = tableCategories.map((m) => ({
    label: m.text,
    value: m.value,
  }));

  if (!state.title) return null;

  return (
    <div>
      <Taggit
        reqInfo={{
          report_recipe_id: id,
        }}
      />
      <Divider mt="lg" mb="lg" />
      <TextInput
        label="Title"
        required
        value={state.title}
        mb="xs"
        onChange={(e) =>
          setState({
            ...state,
            title: e.target.value,
          })
        }
      />
      <Textarea
        label="Description"
        required
        value={state.description}
        mb="xs"
        onChange={(e) =>
          setState({
            ...state,
            description: e.target.value,
          })
        }
      />
      <MultiSelect
        required
        label="Table Categories"
        searchable
        placeholder="Select..."
        value={state.table_categories.map((m) => `${m}`)}
        data={tableCategoryOptions}
        multiple
        onChange={(e) => {
          setState({
            ...state,
            table_categories: e,
          });
        }}
      />
      <Switch
        label="Shareable"
        mt="lg"
        checked={state.shareable}
        onChange={(e) =>
          setState({
            ...state,
            shareable: !state.shareable,
          })
        }
      />
      {state.query_components?.length > 0 && (
        <>
          <Text mt="lg" mb="md" fw={600} size="sm">
            Query Components
          </Text>
          {state.query_components.map((m, i) => (
            <div key={i}>
              <Text size="xs" mb="xs" fw={600}>
                Query Component #{i + 1} ({m.type})
              </Text>
              <TextInput
                mb="xs"
                label="Label"
                value={m.label}
                required
                onChange={(e) => {
                  onQcChange(m, {
                    key_to_update: "label",
                    value: e.target.value,
                  });
                }}
              />
              <TextInput
                label="Tooltip"
                value={m.tooltip_text}
                onChange={(e) => {
                  onQcChange(m, {
                    key_to_update: "tooltip_text",
                    value: e.target.value,
                  });
                }}
              />
              {i + 1 < state.query_components.length && (
                <Divider mt="lg" mb="lg" />
              )}
            </div>
          ))}
        </>
      )}
      <Button
        disabled={!validated}
        mt="md"
        w="fit-content"
        onClick={onSubmit}
        loading={loading}
      >
        Save
      </Button>
    </div>
  );
}
