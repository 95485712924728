import React, { useState } from "react";
import { TextInput, Switch } from "@mantine/core";
import { useSelector } from "react-redux";

import { PrizePoolForm } from "./";

const initialFormValues = {
  name: "",
  allow_location_access: true,
};

export default function PrizePoolCreate({
  organizationId,
  locationId,
  reqData,
  onSuccess,
}) {
  const [formValues, setFormValues] = useState(initialFormValues);

  const managerInfo = useSelector((state) => state.manager);

  return (
    <div>
      <TextInput
        label="Name"
        required
        value={formValues.name}
        onChange={(e) =>
          setFormValues({
            ...formValues,
            name: e.target.value,
          })
        }
      />
      {!managerInfo && !reqData.location_id && (
        <Switch
          mt="xs"
          mb="md"
          onLabel="ON"
          offLabel="OFF"
          label="Allow Location Access"
          checked={formValues.allow_location_access}
          onChange={() =>
            setFormValues({
              ...formValues,
              allow_location_access: !formValues.allow_location_access,
            })
          }
        />
      )}
      <PrizePoolForm
        organizationId={organizationId}
        locationId={locationId}
        onSuccess={onSuccess}
        name={formValues.name}
        retailValue={formValues.retail_value}
        allowLocationAccess={formValues.allow_location_access}
      />
    </div>
  );
}
