import React, { useState } from "react";
import { Button, Card, Modal, Title } from "@mantine/core";

import { PrizeForm, PrizeList } from "./";

export default function PrizeManagement({
  contestId,
  locationId,
  organizationId,
  fetchData,
  isPrizePool = false,
  isAuction = false,
  minimal,
  onModalClose,
  showPrizeEdit = true,
}) {
  const [refresh, setRefresh] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  function onClose() {
    setModalOpen(false);

    if (onModalClose) {
      return onModalClose();
    }
  }

  return (
    <div>
      {!isPrizePool && !isAuction && (
        <Button
          size="xs"
          mb="sm"
          variant="light"
          onClick={() => setModalOpen(true)}
        >
          create prize
        </Button>
      )}
      <Modal title="Create prize" opened={modalOpen} onClose={onClose}>
        <PrizeForm
          contestId={contestId}
          onSuccess={() => {
            setRefresh(!refresh);
            setModalOpen(false);
            if (fetchData) {
              fetchData();
            }
          }}
        />
      </Modal>
      <PrizeList
        contestId={contestId}
        isAuction={isAuction}
        isPrizePool={isPrizePool}
        locationId={locationId}
        minimal={minimal}
        organizationId={organizationId}
        refresh={refresh}
        onModalClose={onModalClose}
        showPrizeEdit={showPrizeEdit}
      />
    </div>
  );
}
