import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  List,
  Flex,
  Divider,
  Tabs,
  Text,
  Table,
  TextInput,
  Modal,
  Button,
  Select,
  SegmentedControl,
  ActionIcon,
} from "@mantine/core";
import axios from "axios";
import toast from "react-hot-toast";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import {
  getCoreRowModel,
  useReactTable,
  getFilteredRowModel,
  getPaginationRowModel,
} from "@tanstack/react-table";
import {
  IconListDetails,
  IconPencil,
  IconTrash,
  IconShare,
  IconDeviceFloppy,
  IconCaretUp,
  IconCaretDown,
  IconBell,
} from "@tabler/icons-react";

import EffortBuilderForm from "@components/EffortBuilder/EffortBuilderForm";
import { LocationSelect } from "@components/Location";
import { OrganizationSelect } from "@components/Organization";

export default function EffortSavedForms({ effortId, hasCustomQuestion }) {
  const queryClient = useQueryClient();
  const [isOpen, setOpen] = useState(false);

  function onClose() {
    setOpen(false);
  }

  return (
    <>
      <Button
        size="xs"
        radius="lg"
        variant="light"
        onClick={() => setOpen(true)}
      >
        Saved Forms
      </Button>
      <Modal opened={isOpen} onClose={onClose}>
        <SavedFormList
          effortId={effortId}
          hasCustomQuestion={hasCustomQuestion}
          onAddToEffort={() => {
            setOpen(false);
            queryClient.invalidateQueries([`effort${effortId}Configuration`]);
          }}
        />
      </Modal>
    </>
  );
}

function SavedFormList({ effortId, onAddToEffort, hasCustomQuestion }) {
  const [searchValue, setSearchValue] = useState("");
  const [loading, setLoading] = useState(true);
  const [results, setResults] = useState([]);
  const [total, setTotal] = useState(0);
  const [pageCount, setPageCount] = useState(-1);

  const table = useReactTable({
    manualPagination: true,
    pageCount,
    data: results,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    initialState: {
      pagination: {
        pageSize: 10,
      },
    },
  });

  const CancelToken = axios.CancelToken;
  const cancelRef = useRef(null);

  useEffect(() => {
    fetchForms();
  }, [searchValue, JSON.stringify(table.getState().pagination)]);

  function fetchForms() {
    setLoading(true);

    const cancel = cancelRef.current;
    if (cancel) cancel();

    const req = {
      page_size: table.getState().pagination.pageSize,
      page:
        table.getState().pagination.pageIndex === -1
          ? 0
          : table.getState().pagination.pageIndex,
      variety: 1,
      search: searchValue,
    };

    axios
      .post(`/retrieve-saved-forms/`, req, {
        cancelToken: new CancelToken(function executor(c) {
          cancelRef.current = c;
        }),
      })
      .then(({ data }) => {
        setLoading(false);
        setResults(data.response[0].data);
        setPageCount(data.response[0].page_count);
        setTotal(data.response[0].total);
      })
      .catch((err) => {
        setLoading(false);
      });
  }

  return (
    <div>
      <TextInput
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
        placeholder="Search saved forms"
        mb="sm"
      />
      {!loading && results.length === 0 && (
        <Text size="xs" mt="xs">
          No saved forms yet...
        </Text>
      )}
      {results.length > 0 && (
        <>
          {results.map((result, i) => (
            <Box key={result.id}>
              <SavedForm
                effortId={effortId}
                result={result}
                id={result.id}
                fetchData={fetchForms}
                onAddToEffort={onAddToEffort}
                title={result.title}
                hasCustomQuestion={hasCustomQuestion}
              />
              {i < results.length - 1 && <Divider mt="lg" mb="lg" />}
            </Box>
          ))}
          {pageCount > 1 && (
            <Flex align="center" mt="lg" gap="xs">
              <Button
                size="xs"
                variant="light"
                fullWidth
                disabled={table.getState().pagination.pageIndex === 0}
                onClick={() =>
                  table.setPageIndex(table.getState().pagination.pageIndex - 1)
                }
              >
                Back
              </Button>
              <Button
                size="xs"
                fullWidth
                variant="light"
                disabled={
                  table.getState().pagination.pageIndex === pageCount - 1
                }
                onClick={() =>
                  table.setPageIndex(table.getState().pagination.pageIndex + 1)
                }
              >
                Next
              </Button>
            </Flex>
          )}
        </>
      )}
    </div>
  );
}

function SavedForm({
  result,
  id,
  effortId,
  fetchData,
  title,
  onAddToEffort,
  hasCustomQuestion,
}) {
  const managerInfo = useSelector((state) => state.manager);
  const [info, setInfo] = useState(null);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(null);
  const [error, setError] = useState(null);
  const [editing, setEditing] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [formValues, setFormValues] = useState({
    title: "",
  });

  useEffect(() => {
    fetchForm();
  }, [id, open]);

  function fetchForm() {
    if (!open) return;
    axios
      .get(`/saved-forms/${id}/`)
      .then(({ data }) => {
        setInfo(data.response[0]);
        setFormValues({
          title: data.response[0].title,
        });
      })
      .catch((err) => {
        setInfo(null);
        setError(err);
      });
  }

  function onDelteClick() {
    axios
      .delete(`/saved-forms/${id}/`)
      .then(() => {
        fetchData();
      })
      .catch((err) => {
        toast.error(err);
      });
  }

  function onUpdateClick() {
    const req = {
      ...formValues,
    };

    setUpdating(true);

    axios
      .put(`/saved-forms/${id}/`, req)
      .then(() => {
        toast.success("Updated!");
        setUpdating(false);
        fetchForm();
        fetchData();
        setEditing(false);
      })
      .catch((err) => {
        toast.error(err);
        setUpdating(false);
      });
  }

  if (error) {
    return <Text>{error}</Text>;
  }

  const updatedBy =
    JSON.stringify(result.updated_by) === "{}" ? null : result.updated_by;
  const sharedBy =
    JSON.stringify(result.shared_by) === "{}" ? null : result.shared_by;
  const createdBy =
    JSON.stringify(result.created_by) === "{}" ? null : result.created_by;

  const subtitleProps = {
    size: "xs",
    c: "dimmed",
    fs: "italic",
  };

  return (
    <Box>
      <Flex align="flex-start">
        <Box style={{ flexGrow: 1, marginBottom: 0 }}>
          <Text fw={600}>{title}</Text>
          {result.version && (
            <Text {...subtitleProps}>Version {result.version}</Text>
          )}
          {updatedBy ? (
            <>
              <Text {...subtitleProps}>updated by {updatedBy.full_name}</Text>
              <Text {...subtitleProps}>
                updated {new Date(result.updated_at).toLocaleString()}
              </Text>
            </>
          ) : sharedBy ? (
            <>
              <Text {...subtitleProps}>
                shared by {sharedBy.full_name} ({sharedBy.class_name})
              </Text>
              <Text {...subtitleProps}>
                {new Date(result.created_at).toLocaleString()}
              </Text>
            </>
          ) : createdBy ? (
            <>
              <Text {...subtitleProps}>created by {createdBy.full_name}</Text>
              <Text {...subtitleProps}>
                {new Date(result.created_at).toLocaleString()}
              </Text>
            </>
          ) : null}
        </Box>
        <ActionIcon
          onClick={() => setOpen(!open)}
          variant="subtle"
          color="gray"
          size="lg"
          radis="xl"
        >
          {open ? <IconCaretUp stroke={1.5} /> : <IconCaretDown stroke={1.5} />}
        </ActionIcon>
      </Flex>
      <Box>
        {open && (
          <Tabs mt="sm" defaultValue="preview" color="dark">
            <Tabs.List mb="sm">
              <Tabs.Tab
                value="preview"
                leftSection={<IconListDetails size={14} />}
              >
                Preview
              </Tabs.Tab>
              <Tabs.Tab value="edit" leftSection={<IconPencil size={14} />}>
                Edit
              </Tabs.Tab>
              {(!managerInfo || (managerInfo && !managerInfo.location_id)) && (
                <Tabs.Tab value="share" leftSection={<IconShare size={14} />}>
                  Share
                </Tabs.Tab>
              )}
            </Tabs.List>
            <Tabs.Panel value="preview">
              {info && info.form_data && (
                <Box mt="sm">
                  <StyledForm>
                    <EffortBuilderForm
                      onSubmit={(e) => true}
                      fields={info.form_data.fields.map((m) => ({
                        ...m,
                        name: m.name ? m.name : m.uuid,
                      }))}
                    />
                  </StyledForm>
                  {hasCustomQuestion ? (
                    <Flex gap="xs" align="center">
                      <IconBell size={48} color="var(--mantine-color-red-5)" />
                      <Text size="sm">
                        This effort contains a custom question. If you wish to
                        add this saved form to the effort, get rid of the custom
                        question(s).
                      </Text>
                    </Flex>
                  ) : (
                    <AddToEffortButton
                      onSuccess={onAddToEffort}
                      effortId={effortId}
                      savedFormId={id}
                    />
                  )}
                </Box>
              )}
            </Tabs.Panel>
            <Tabs.Panel value="edit">
              <TextInput
                label="Title"
                value={formValues.title}
                onChange={(e) =>
                  setFormValues({
                    ...formValues,
                    title: e.target.value,
                  })
                }
              />
              <Button
                disabled={!formValues.title}
                fullWidth
                mt="sm"
                onClick={onUpdateClick}
                loading={updating}
                leftSection={<IconDeviceFloppy size={16} />}
              >
                Save
              </Button>
              <Button
                variant="subtle"
                // size="xs"
                fullWidth
                color="gray"
                mt="sm"
                onClick={() => {
                  if (
                    window.confirm(
                      "Do you really want to delete this saved form?"
                    )
                  ) {
                    onDelteClick();
                  }
                }}
              >
                Delete
              </Button>
            </Tabs.Panel>
            <Tabs.Panel value="share">
              <ShareForm savedFormId={id} />
            </Tabs.Panel>
          </Tabs>
        )}
      </Box>
    </Box>
  );
}

const ShareForm = ({ savedFormId }) => {
  const managerInfo = useSelector((state) => state.manager);
  const [loading, setLoading] = useState(false);
  const [locations, setLocations] = useState([]);
  const [orgs, setOrgs] = useState([]);
  const [organizationValue, setOrganizationValue] = useState(null);
  const [shareAllLoading, setShareAllLoading] = useState(false);
  const [inputsVisible, setInputsVisible] = useState(true);
  const [entity, setEntity] = useState("locations");

  if (managerInfo && managerInfo.location_id) return null;

  function onShareClick() {
    setLoading(true);

    const req = {
      saved_form_id: savedFormId,
      share_with_organizations: false,
      share_with_locations: false,
    };

    if (entity === "locations") {
      req.location_ids = locations.map((l) => l.id);
      req.organization_ids = [];
    } else {
      req.organization_ids = orgs.map((o) => o.id);
      req.location_ids = [];
    }

    axios
      .post(`/saved-forms/${savedFormId}/share-form/`, req)
      .then(() => {
        toast.success("Shared!");
        setLoading(false);
        setLocations([]);
        setOrgs([]);
        setInputsVisible(false);
        setTimeout(() => {
          setInputsVisible(true);
        }, 1);
      })
      .catch((err) => {
        toast.error(err);
        setLoading(false);
      });
  }

  function onShareAllClick(context) {
    setShareAllLoading(true);

    const req = {
      organization_ids: [],
      location_ids: [],
      saved_form_id: savedFormId,
      share_with_organizations: false,
      share_with_locations: false,
    };

    if (managerInfo) {
      req.organization_ids = [managerInfo.organization_id];
      req.share_with_locations = true;
    } else {
      // is admin doin it
      if (context === "adminOrgLocations") {
        req.share_with_locations = true;
        req.organization_ids = orgs.map((o) => o.id);
      } else {
        if (entity === "locations") {
          req.share_with_locations = true;
        } else {
          req.share_with_organizations = true;
        }
      }
    }

    axios
      .post(`/saved-forms/${savedFormId}/share-form/`, req)
      .then(() => {
        toast.success("Shared!");
        setShareAllLoading(false);
        setLocations([]);
        setOrgs([]);
      })
      .catch((err) => {
        setShareAllLoading(false);
        toast.error(err);
      });
  }

  return (
    <>
      {!managerInfo && (
        <SegmentedControl
          value={entity}
          onChange={(e) => setEntity(e)}
          data={[
            { label: "Locations", value: "locations" },
            { label: "Organizations", value: "organizations" },
          ]}
          mb="sm"
          size="xs"
          fullWidth
        />
      )}
      <>
        {entity === "locations" ? (
          <>
            {inputsVisible ? (
              <>
                <LocationSelect
                  value=""
                  // value={locationValue ? locationValue.id : ""}
                  objectOnChange
                  label=""
                  disabledIds={locations.map((l) => l.id)}
                  onChange={(e) => {
                    if (e === null) {
                      // setLocationValue(null);
                    } else {
                      setLocations([...locations, e]);
                      setInputsVisible(false);
                      setTimeout(() => {
                        setInputsVisible(true);
                      }, 1);
                      // setLocationValue(e);
                    }
                  }}
                />
              </>
            ) : (
              <Select />
            )}
            {locations.length > 0 && (
              <Box
                style={{ maxHeight: "250px", overflowY: "auto" }}
                mt="sm"
                mb="sm"
              >
                <Table striped>
                  <Table.Tbody>
                    {locations.map((location) => (
                      <Table.Tr key={location.id}>
                        <Table.Td>{location.name}</Table.Td>
                        <Table.Td>
                          <Box
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <ActionIcon
                              size="xs"
                              color="red"
                              radius="xl"
                              variant="light"
                              onClick={() =>
                                setLocations(
                                  locations.filter((f) => f.id !== location.id)
                                )
                              }
                            >
                              <IconTrash />
                            </ActionIcon>
                          </Box>
                        </Table.Td>
                      </Table.Tr>
                    ))}
                  </Table.Tbody>
                </Table>
              </Box>
            )}
          </>
        ) : (
          <>
            {inputsVisible ? (
              <OrganizationSelect
                value=""
                objectOnChange
                label=""
                disabledIds={orgs.map((l) => l.id)}
                onChange={(e) => {
                  if (e === null) {
                    // setLocationValue(null);
                  } else {
                    setOrgs([...orgs, e]);
                    setInputsVisible(false);
                    setTimeout(() => {
                      setInputsVisible(true);
                    }, 1);
                    // setLocationValue(e);
                  }
                }}
              />
            ) : (
              <Select label="Organization" />
            )}
            {orgs.length > 0 && (
              <Box
                style={{ maxHeight: "250px", overflowY: "auto" }}
                mt="sm"
                mb="sm"
              >
                <Table striped>
                  <Table.Tbody>
                    {orgs.map((org) => (
                      <Table.Tr key={org.id}>
                        <Table.Td>{org.name}</Table.Td>
                        <Table.Td>
                          <Box
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <ActionIcon
                              size="xs"
                              color="red"
                              radius="xl"
                              variant="light"
                              onClick={() =>
                                setOrgs(orgs.filter((f) => f.id !== org.id))
                              }
                            >
                              <IconTrash />
                            </ActionIcon>
                          </Box>
                        </Table.Td>
                      </Table.Tr>
                    ))}
                  </Table.Tbody>
                </Table>
              </Box>
            )}
          </>
        )}
      </>
      <Button
        color="teal"
        fullWidth
        mt="xs"
        onClick={onShareClick}
        loading={loading}
        leftSection={<IconShare size={16} />}
        disabled={
          entity === "locations" ? locations.length === 0 : orgs.length === 0
        }
      >
        Share with{" "}
        {entity === "locations" ? `(${locations.length})` : `(${orgs.length})`}{" "}
        selected {entity}
      </Button>
      {!managerInfo && entity === "organizations" && orgs.length === 1 && (
        <>
          <Divider label="OR" mt="sm" mb="sm" />
          <Button
            color="teal"
            fullWidth
            mt="xs"
            onClick={() => onShareAllClick("adminOrgLocations")}
            loading={loading}
            leftSection={<IconShare size={16} />}
            disabled={orgs.length !== 1}
          >
            Share with selected org & its locations
          </Button>
        </>
      )}
      <Button
        fullWidth
        mt="xs"
        variant="subtle"
        color="gray"
        loading={shareAllLoading}
        onClick={onShareAllClick}
      >
        Share with all {entity}
      </Button>
    </>
  );
};

const AddToEffortButton = ({ effortId, savedFormId, onSuccess }) => {
  const [confirm, setConfirm] = useState(false);
  const [loading, setLoading] = useState(false);

  function onSubmit() {
    setLoading(true);

    const req = {
      campaign_effort_id: effortId,
      saved_form_id: savedFormId,
    };

    axios
      .post(`/efforts/${effortId}/add-builder-data/`, req)
      .then(() => {
        setLoading(false);
        toast.success("Added!");
        onSuccess();
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  return (
    <>
      {!confirm ? (
        <Button
          mt="lg"
          fullWidth
          color="green"
          onClick={() => setConfirm(true)}
        >
          Add to effort
        </Button>
      ) : (
        <>
          <Text align="center" fw={600} mb="sm">
            This action will replace this effort's fields with the ones
            displayed above. Are you sure you want to do this?
          </Text>
          <Button fullWidth onClick={onSubmit} loading={loading}>
            I'm Sure. Do It!
          </Button>
          <Button
            fullWidth
            mt="sm"
            variant="subtle"
            color="gray"
            onClick={() => setConfirm(false)}
          >
            No thanks.
          </Button>
        </>
      )}
    </>
  );
};

const StyledForm = styled.div`
  button {
    display: none;
  }
`;

export function SaveFormButton({ effortName, fields }) {
  const [loading, setLoading] = useState(false);

  function onClick() {
    setLoading(true);

    const req = {
      title: effortName,
      variety: 1,
      form_data: {
        fields,
      },
    };

    axios
      .post(`/saved-forms/`, req)
      .then(() => {
        toast.success("Saved!");
        setLoading(false);
      })
      .catch((err) => {
        toast.error(err);
        setLoading(false);
      });
  }

  return (
    <Button loading={loading} onClick={onClick} color="teal" variant="light">
      Save to library
    </Button>
  );
}
