import React, { useState } from "react";
import axios from "axios";
import * as Yup from "yup";
import toast from "react-hot-toast";
import {
  Group,
  Flex,
  Title,
  Text,
  useMantineTheme,
  Divider,
} from "@mantine/core";
import { IconBug, IconCircleCheck } from "@tabler/icons-react";
import DeviceDetector from "device-detector-js";

import { BasicForm } from "@components/shared";
import { phoneValidation } from "@util/validation";

export default function TicketEntryForm({
  firstName = "",
  lastName = "",
  email = "",
  phone = "",
  description = "",
  reqData = null,
}) {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const theme = useMantineTheme();
  const deviceDetector = new DeviceDetector();
  const userAgent =
    window && window.navigator ? window.navigator.userAgent : null;
  const device = userAgent ? deviceDetector.parse(userAgent) : null;

  function onSubmit(formData) {
    setLoading(true);

    let req = {
      ...formData,
    };

    if (reqData) {
      req = {
        ...req,
        ...reqData,
      };
    }

    if (device) req.device = device;

    axios
      .post(`/trouble-tickets/`, req)
      .then(() => {
        setLoading(false);
        setSuccess(true);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  const fields = [
    {
      name: "first_name",
      label: "First Name",
      initialValue: firstName,
      required: true,
      schema: () => Yup.string().required("Required"),
    },
    {
      name: "last_name",
      label: "Last Name",
      initialValue: lastName,
      required: true,
      schema: () => Yup.string().required("Required"),
    },
    {
      name: "email",
      label: "Email you were using",
      initialValue: email,
      required: true,
      schema: () =>
        Yup.string().email("Not a valid email").required("Required"),
    },
    {
      name: "phone",
      label: "Phone number you were using",
      initialValue: phone,
      mask: {
        pattern: "(999) 999-9999",
        placeholder: "(XXX) XXX-XXXX",
      },
      required: true,
      schema: () =>
        Yup.string()
          .matches(phoneValidation, "Not a valid phone number")
          .required("Required"),
    },
    {
      name: "issue_description",
      label: "What did you experience? (255 characters or less please)",
      initialValue: description,
      textarea: true,
      required: true,
      schema: () =>
        Yup.string()
          .max(255, "255 characters or less please")
          .required("Required"),
    },
  ];

  return (
    <React.Fragment>
      {success ? (
        <React.Fragment>
          <TitleSection
            title="Thank you!"
            subtitle="We've received your report and will investigate soon."
            icon={<IconCircleCheck size={60} color={theme.colors.green[6]} />}
          />
        </React.Fragment>
      ) : (
        <React.Fragment>
          <TitleSection
            title="Found a bug?"
            subtitle="Describe it below to help us squash it."
            icon={<IconBug size={60} color={theme.colors.red[6]} />}
          />
          <Divider mt="sm" mb="xs" variant="dashed" />
          <BasicForm
            loading={loading}
            onSubmit={onSubmit}
            fields={fields}
            buttonText="Send"
            buttonProps={{
              fullWidth: true,
              color: "green",
            }}
          />
        </React.Fragment>
      )}
    </React.Fragment>
  );
}

const TitleSection = ({ title, subtitle, icon }) => {
  return (
    <Flex>
      <div style={{ flexGrow: 1 }}>
        <Title>{title}</Title>
        <Text>{subtitle}</Text>
      </div>
      <Group>{icon}</Group>
    </Flex>
  );
};
