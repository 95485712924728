import React, { useState, useEffect } from "react";
import axios from "axios";
import { Radio, Tabs, Grid, Space, Stack, Title } from "@mantine/core";
import toast from "react-hot-toast";

import { Gauge } from "@components/DataViz";
import {
  EntityConfigBaseColor,
  EntityConfigIcon,
  EntityConfigLinks,
  EntityConfigColorFields,
  EntityConfigTextFields,
  EntityConfigSaveButton,
} from "@components/EntityConfig";

import ConfigAssets from "./ConfigAssets";
import ConfigSettings from "./ConfigSettings";

import entityColor from "@util/entityColor";
import { getUuid } from "@util/getUuid";

import { textItems, colorItems, initialConfig } from "./helpers";

export const tabs = [
  { title: "Settings", value: "settings", icon: <EntityConfigIcon.Settings /> },
  { title: "Text", value: "text", icon: <EntityConfigIcon.Text /> },
  { title: "Colors", value: "colors", icon: <EntityConfigIcon.Color /> },
  { title: "Assets", value: "assets", icon: <EntityConfigIcon.Asset /> },
  {
    title: "Components",
    value: "components",
    icon: <EntityConfigIcon.Component />,
  },
  {
    title: "Links",
    value: "links",
    icon: <EntityConfigIcon.Link />,
  },
].map((m) => ({
  ...m,
  key: getUuid(),
}));

export default function MicrositeSettings({
  campaignId,
  contestId,
  effortId,
  locationId,
  onUpdate,
}) {
  const [jsonData, setJsonData] = useState(null);
  const [links, setLinks] = useState(initialConfig.links);
  const [colors, setColors] = useState(initialConfig.colors);
  const [text, setText] = useState(initialConfig.text);
  const [components, setComponents] = useState(initialConfig.components);
  const [settings, setSettings] = useState(initialConfig.settings);
  const [loading, setLoading] = useState(true);

  const routes = {
    fetch: campaignId
      ? `/campaigns/${campaignId}/app-configuration/`
      : effortId
      ? `/efforts/${effortId}/app-configuration/`
      : `/contests/${contestId}/app-configuration/`,
    update: campaignId
      ? `/campaigns/${campaignId}/configuration/`
      : effortId
      ? `/efforts/${effortId}/configuration/`
      : `/contests/${contestId}/configuration/`,
  };

  useEffect(() => {
    fetchData();
  }, []);

  function fetchData() {
    axios
      .get(routes.fetch)
      .then(({ data }) => {
        const config = data.response[0];
        if (JSON.stringify(config) !== "{}") {
          setJsonData(config);

          if (config.colors) {
            setColors({
              ...colors,
              ...config.colors,
            });
          }

          if (config.text) {
            setText({
              ...text,
              ...config.text,
            });
          }

          if (config.components) {
            setComponents({
              ...components,
              ...config.components,
            });
          }

          if (config.settings) {
            setSettings({
              ...settings,
              ...config.settings,
            });
          }

          if (config.links !== undefined) {
            setLinks([...config.links]);
          }
        } else {
          setJsonData({});
        }
      })
      .then(() => setLoading(false))
      .then(() => fetchAssets())
      .catch((err) => {});
  }

  function updateConfig(newConfig) {
    const req = {
      configuration: { ...jsonData, ...newConfig },
    };

    axios
      .put(routes.update, req)
      .then(() => {
        fetchData();
        toast.success("Config updated!");
      })
      .then(() => onUpdate())
      .catch((err) => {
        toast.error(err);
      });
  }

  function onColorsSave() {
    updateConfig({ ...jsonData, colors });
  }

  function onTextSave() {
    updateConfig({ ...jsonData, text });
  }

  function onComponentsSave() {
    updateConfig({ ...jsonData, components });
  }

  function onLinksUpdate(newLinks) {
    updateConfig({ ...jsonData, links: [...newLinks] });
  }

  function onSettingsSave() {
    updateConfig({ ...jsonData, settings });
  }

  if (loading) return <div></div>;

  // if (!jsonData) return null;

  return (
    <Tabs defaultValue="settings">
      <Tabs.List mb="lg">
        {tabs.map((tab) => (
          <Tabs.Tab key={tab.key} value={tab.value} icon={tab.icon}>
            {tab.title}
          </Tabs.Tab>
        ))}
      </Tabs.List>
      <Tabs.Panel value="text">
        <EntityConfigTextFields
          fields={textItems.filter((f) =>
            locationId
              ? ![
                  "location_question",
                  "location_search_title",
                  "location_search_subtitle",
                ].includes(f.name)
              : true
          )}
          values={text}
          onChange={(e) =>
            setText({
              ...text,
              ...e,
            })
          }
        />
        <EntityConfigSaveButton onClick={onTextSave} />
      </Tabs.Panel>
      <Tabs.Panel value="assets">
        <ConfigAssets
          campaignId={campaignId}
          contestId={contestId}
          effortId={effortId}
          updateConfig={updateConfig}
          jsonData={jsonData}
        />
      </Tabs.Panel>
      <Tabs.Panel value="links">
        <EntityConfigLinks links={links} onUpdate={onLinksUpdate} />
      </Tabs.Panel>
      <Tabs.Panel value="colors">
        <Title mb="xs" order={4}>
          Site Button Color
        </Title>
        <EntityConfigBaseColor
          onChange={(e) => setColors({ ...colors, base: e })}
          value={colors.base}
        />
        <Space h="xl" />
        <EntityConfigColorFields
          values={colors}
          fields={colorItems}
          onChange={(e) => setColors({ ...colors, ...e })}
        />
        <EntityConfigSaveButton onClick={onColorsSave} />
      </Tabs.Panel>
      <Tabs.Panel value="components">
        <Title mb="xs" order={4}>
          Entry Progress Visualization
        </Title>
        <Grid>
          <Grid.Col span={{ base: 6 }}>
            <Stack>
              <Gauge />
              <Radio
                name="vizProgress"
                label="Variant 1"
                checked={components.gauge_variant === 1}
                onChange={() =>
                  setComponents({
                    ...components,
                    gauge_variant: 1,
                  })
                }
              />
            </Stack>
          </Grid.Col>
          <Grid.Col span={{ base: 6 }}>
            <Stack align="center" justify="center">
              <Gauge variant={2} />
              <Radio
                mt="lg"
                name="vizProgress"
                label="Variant 2"
                checked={components.gauge_variant === 2}
                onChange={() =>
                  setComponents({
                    ...components,
                    gauge_variant: 2,
                  })
                }
              />
            </Stack>
          </Grid.Col>
        </Grid>
        <EntityConfigSaveButton onClick={onComponentsSave} />
      </Tabs.Panel>
      <Tabs.Panel value="settings">
        <ConfigSettings
          settings={settings}
          onChange={(newSettings) => setSettings(newSettings)}
          locationId={locationId}
        />
        <EntityConfigSaveButton onClick={onSettingsSave} />
      </Tabs.Panel>
    </Tabs>
  );
}
