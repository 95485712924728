import React, { useState, useEffect } from "react";
import axios from "axios";
import { Badge } from "@mantine/core";

import { getUuid } from "@util/getUuid";
import { AssetManagement, AssetLibraryPicker } from "@components/Asset";
import {
  addFileToConfig,
  formatPlacements,
  renameFileInConfig,
  removeFileInConfig,
} from "@components/Asset/helpers";

import { placementDefs } from "@components/Asset/placementDefs";

const placements = [
  {
    text: "Info Modal Featured Image",
    value: placementDefs.featured,
    limit: 1,
  },
  { text: "Site Background", value: placementDefs.background, limit: 1 },
].map((m) => ({
  ...m,
  key: getUuid(),
}));

export default function ConfigAssets({
  campaignId,
  contestId,
  effortId,
  updateConfig,
  jsonData,
}) {
  const [assets, setAssets] = useState([]);

  useEffect(() => {
    fetchAssets();
  }, [JSON.stringify(jsonData)]);

  function fetchAssets() {
    axios
      .post(`/retrieve-assets/`, {
        campaign_effort_id: effortId,
        contest_id: contestId,
        campaign_id: campaignId,
      })
      .then(({ data }) => {
        setAssets(
          data.response[0].data.map((d) => ({
            ...d,
            ...d.asset,
          }))
        );
      })
      .catch((err) => {
        setAssets([]);
      });
  }

  function onUploadSuccess(newId, placementValue) {
    updateConfig(addFileToConfig(jsonData, newId, placementValue));
  }

  function onRemoveSuccess(removedId) {
    updateConfig(removeFileInConfig(jsonData, removedId));
  }

  function onRenameSuccess(assetId, newName) {
    updateConfig(renameFileInConfig(jsonData, { id: assetId, name: newName }));
  }

  function onLibrarySelection(itemId, placementValue) {
    const req = {
      campaign_effort_id: effortId,
      campaign_id: campaignId,
      contest_id: contestId,
      asset_id: itemId,
      variety: effortId ? 2 : campaignId ? 3 : contestId ? 5 : null,
    };

    axios
      .post(`/replicate-library-asset/`, req)
      .then(({ data }) => {
        onUploadSuccess(data.response[0].id, placementValue);
      })
      .catch((err) => {
        fetchAssets();
        toast.error(err);
      });
  }

  const formattedPlacements = formatPlacements(placements, jsonData, assets);

  return (
    <React.Fragment>
      {formattedPlacements.map((p) => (
        <AssetManagement
          library={false}
          campaignId={campaignId}
          contestId={contestId}
          effortId={effortId}
          items={p.assets}
          title={
            <React.Fragment>
              {p.text}{" "}
              {p.limit && (
                <Badge variant="light" color="yellow">
                  Limit of {p.limit}
                </Badge>
              )}
            </React.Fragment>
          }
          key={p.value}
          limit={p.limit}
          onRenameSuccess={onRenameSuccess}
          onRemoveSuccess={onRemoveSuccess}
          onUploadSuccess={(newId) => onUploadSuccess(newId, p.value)}
          shouldFetch={false}
        >
          {p.assets.length < p.limit && (
            <AssetLibraryPicker
              onClose={fetchAssets}
              onSelect={(assetId) => onLibrarySelection(assetId, p.value)}
              reqData={{
                variety: [2, 3, 4],
              }}
            />
          )}
        </AssetManagement>
      ))}
    </React.Fragment>
  );
}
