import React, { useEffect, useState } from "react";
import axios from "axios";
import { Button, Tabs } from "@mantine/core";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";

import { OrganizationSelect, OrganizationList } from "@components/Organization";
import { LocationAssociate } from "@components/Location";
import { Header, ManagementLayout } from "@components/shared";

export default function CampaignParticipants({ campaignId, organizationId }) {
  const [locations, setLocations] = useState([]);
  const [loading, setLoading] = useState(true);

  const managerInfo = useSelector((state) => state.manager);
  const isOrgManager = managerInfo && managerInfo.location_id ? true : false;

  useEffect(() => {
    fetchLocations();
  }, []);

  function fetchLocations() {
    setLoading(true);
    axios
      .get(`/campaigns/${campaignId}/retrieve-locations/`)
      .then(({ data }) => {
        setLoading(false);
        setLocations(
          data.response.map((m) => ({
            ...m,
            link_url: `/locations/${m.id}`,
          }))
        );
      })
      .catch((err) => {
        setLoading(false);
        setLocations([]);
      });
  }

  const locationIds = locations.map((m) => m.id);

  return (
    <LocationAssociate
      fetchData={fetchLocations}
      campaignId={campaignId}
      disabledIds={locationIds}
      selectReqData={{
        organization_id: organizationId,
        status: 1,
      }}
    />
  );

  // return (
  //   <div>
  //     <Tabs
  //       defaultValue={isOrgManager ? "locations" : "orgs"}
  //       keepMounted={false}
  //     >
  //       {!isOrgManager && (
  //         <Tabs.List>
  //           <Tabs.Tab value="orgs">Organizations</Tabs.Tab>
  //           <Tabs.Tab value="locations">Locations</Tabs.Tab>
  //         </Tabs.List>
  //       )}
  //       <Tabs.Panel value="orgs">
  //         <div>
  //           <ManagementLayout
  //             dataComponent={<OrganizationList items={organizations} />}
  //             createComponent={
  //               <div>
  //                 <Header>Add Organization</Header>
  //                 <OrganizationAdd
  //                   onSuccess={fetchLocations}
  //                   campaignId={campaignId}
  //                   disabledIds={organizationIds}
  //                 />
  //               </div>
  //             }
  //           />
  //         </div>
  //       </Tabs.Panel>
  //       <Tabs.Panel value="locations">
  //         <LocationAssociate
  //           campaignId={campaignId}
  //           selectReqData={{
  //             organization_ids: organizationIds,
  //           }}
  //         />
  //       </Tabs.Panel>
  //     </Tabs>
  //   </div>
  // );
}

const OrganizationAdd = ({ campaignId, disabledIds = [], onSuccess }) => {
  const [value, setValue] = useState(null);
  const [loading, setLoading] = useState(false);

  function onSubmit() {
    setLoading(true);

    const req = {
      organization_id: value,
      campaign_id: campaignId,
    };

    axios
      .post(`/campaigns/${campaignId}/add-participant/`, req)
      .then(() => {
        setLoading(false);
        onSuccess();
        setValue(null);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  return (
    <React.Fragment>
      <OrganizationSelect
        value={value}
        onChange={(e) => setValue(e)}
        disabledIds={disabledIds}
      />
      <Button
        fullWidth
        onClick={onSubmit}
        loading={loading}
        disabled={!value}
        mt="lg"
      >
        Add
      </Button>
    </React.Fragment>
  );
};
