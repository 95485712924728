import React from "react";
import { useGauge } from "use-gauge";
import styled from "styled-components";

const StyledSvg = styled.svg`
  width: 140px;
  padding: 1rem 0 0 0;
  height: 90px;
  overflow: visible;

  text {
    font-size: 0.875rem;
    font-weight: 400;
    fill: gray;
  }

  .stroke-green-300 {
    stroke: #86efac !important;
  }
  .stroke-green-400 {
    stroke: #4ade80 !important;
  }

  .stroke-yellow-300 {
    stroke: #fde047 !important;
  }

  .stroke-red-400 {
    stroke: #f87171 !important;
  }

  .stroke-gray-200 {
    stroke: #e5e7eb;
  }

  .stroke-gray-300 {
    stroke: #d1d5db;
  }

  .fill-gray-300 {
    fill: #e5e7eb;
  }

  .fill-gray-700 {
    fill: #374151;
  }

  .fill-white {
    fill: #fff;
  }
`;

const gaugeTypes = {
  speed: 1,
  arc: 2,
};

const getConfig = (variant, options) => {
  switch (variant) {
    case 1:
      return {
        gauge: {
          domain: options.domain,
          startAngle: 90,
          endAngle: 270,
          numTicks: 21,
          diameter: 200,
        },
        needle: {
          value: options.value,
          baseRadius: 8,
          tipRadius: 2,
        },
      };
    case 2:
      return {
        gauge: {
          domain: options.domain,
          startAngle: 45,
          endAngle: 315,
          numTicks: 0,
          diameter: 200,
        },
        needle: {
          value: options.value,
          baseRadius: 12,
          tipRadius: 2,
        },
      };
  }
};

export default function Gauge({
  value = 49,
  variant = 1,
  domain = [0, 100],
  fillColor = "#86efac",
}) {
  const config = getConfig(variant, { value, domain });

  if (!config) return null;

  const gauge = useGauge(config.gauge);
  const needle = gauge.getNeedleProps(config.needle);

  return (
    <StyledSvg {...gauge.getSVGProps()}>
      {variant === gaugeTypes.arc && (
        <g id="arcs">
          <path
            {...gauge.getArcProps({
              offset: 25,
              startAngle: config.gauge.startAngle,
              endAngle: config.gauge.endAngle,
            })}
            fill="none"
            className="stroke-gray-200"
            strokeLinecap="round"
            strokeWidth={20}
          />
          <path
            {...gauge.getArcProps({
              offset: 25,
              startAngle: config.gauge.startAngle,
              endAngle: gauge.valueToAngle(value),
            })}
            fill="none"
            // className="stroke-green-400"
            stroke={fillColor}
            strokeLinecap="round"
            strokeWidth={20}
          />
        </g>
      )}
      <g id="ticks">
        {gauge.ticks.map((angle) => {
          const asValue = gauge.angleToValue(angle);
          const showText = asValue % 20 === 0 && asValue !== 0;

          return (
            <React.Fragment key={`tick-group-${angle}`}>
              <line
                className={`stroke-gray-300 ${
                  asValue <= 20 && "stroke-green-300"
                } ${asValue >= 60 && asValue <= 80 && "stroke-yellow-300"} ${
                  asValue >= 80 && "stroke-red-400"
                }`}
                strokeWidth={2}
                {...gauge.getTickProps({
                  angle,
                  length: variant === gaugeTypes.speed ? 8 : 6,
                })}
              />
              {showText && (
                <text {...gauge.getLabelProps({ angle, offset: 20 })}>
                  {asValue}
                </text>
              )}
            </React.Fragment>
          );
        })}
      </g>
      {variant === gaugeTypes.speed && (
        <g id="needle">
          <circle className="fill-gray-300" {...needle.base} r={12} />
          <circle className="fill-gray-700" {...needle.base} />
          <circle className="fill-gray-700" {...needle.tip} />
          <polyline className="fill-gray-700" points={needle.points} />
          <circle className="fill-white" {...needle.base} r={4} />
        </g>
      )}
    </StyledSvg>
  );
}
