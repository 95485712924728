import React, { useState, useEffect } from "react";
import axios from "axios";
import { useQueryClient } from "@tanstack/react-query";
import {
  ActionIcon,
  Anchor,
  Box,
  Button,
  Card,
  Divider,
  FileButton,
  Flex,
  Grid,
  Input,
  Loader,
  LoadingOverlay,
  Modal,
  Select,
  Skeleton,
  Tabs,
  Text,
  TextInput,
} from "@mantine/core";
import toast from "react-hot-toast";
import {
  IconTemplate,
  IconTrash,
  IconFileUpload,
  IconPhoto,
  IconForms,
} from "@tabler/icons-react";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { AssetLibraryPicker } from "@components/Asset";
import { Rte } from "@components/shared";
import { emailValidation } from "@util/validation";
import useInterval from "@util/useInterval";
import { urlValidation } from "@util/validation";

const defaultFields = {
  // body: "<p>Testing body</p>",
  reply_to: "testing@mixer.com",
  sender_name: "Tester D. Sender",
  subject: "Testing Subject",
};

const defaultFieldNames = Object.keys(defaultFields);

const defaultTemplateModel = {};

const nonEditableFields = [
  ...defaultFieldNames,
  "branding_color",
  "img",
  "entity_name",
  "facebook_url",
  "instagram_url",
  "logo_url",
  "tiktok_url",
  "twitter_url",
  "youtube_url",
  "copyright",
  "copyright_year",
  "address",
  "contact_us",
  "support_phone",
  "support_email",
];

export default function EmailComposition({
  bulkCommunicationId,
  editable,
  fetchServerTemplate,
  serverTemplateId,
  savedDataModel,
  constants,
  requiredTemplateItems,
  templateAlias,
}) {
  const [saving, setSaving] = useState(false);
  const [templateInfo, setTemplateInfo] = useState(null);
  const [templateLoading, setTemplateLoading] = useState(false);
  const [templateModel, setTemplateModel] = useState({
    ...defaultFields,
    ...defaultTemplateModel,
  });

  const queryClient = useQueryClient();

  useEffect(() => {
    if (savedDataModel) {
      setTemplateModel({
        ...savedDataModel,
      });
    }
  }, [JSON.stringify(savedDataModel)]);

  // useInterval(() => {
  //   if (!templateInfo) return;
  //   validateTemplate();
  // }, 10000);

  useEffect(() => {
    if (!templateAlias) return;
    fetchTemplate();
  }, [templateAlias]);

  function fetchTemplate() {
    setTemplateLoading(true);

    const req = {
      bulk_communicator_id: bulkCommunicationId,
      postmark_template_alias: templateAlias,
    };

    axios
      .post(`/bulk-communicators/templates/postmark-template/`, req)
      .then(({ data }) => {
        const res = data.response[0];
        setTemplateInfo(res);
        setTemplateLoading(false);
        setTemplateModel({
          // ...savedDataModel,
          ...res.validation_response.form_data_model,
        });
      })
      .catch((err) => {
        setTemplateInfo(null);
        setTemplateLoading(false);
      });
  }

  function validateTemplate(newModel = null) {
    if (newModel) {
      setTemplateModel(newModel);
    }
    const srcModel = newModel ? newModel : templateModel;
    const newHash = Object.keys(srcModel).reduce((acc, cur) => {
      if (`${srcModel[cur]}`.indexOf("_Value") > -1) return acc;
      acc[cur] = srcModel[cur];
      return acc;
    }, {});
    const req = {
      html_body: templateInfo.HtmlBody,
      layout_name: templateInfo.LayoutTemplate,
      data_model: {
        ...newHash,
      },
      bulk_communicator_template_id: serverTemplateId,
    };

    axios
      .post(`/bulk-communicators/templates/validate-template/`, req)
      .then(({ data }) => {
        setTemplateInfo({
          ...templateInfo,
          validation_response: {
            ...templateInfo.validation_response,
            HtmlBody: {
              ...data.response[0].HtmlBody,
            },
          },
        });
      })
      .then(() => {
        queryClient.invalidateQueries([
          `bulkCommunications${bulkCommunicationId}Alerts`,
          `bulkCommunications${bulkCommunicationId}`,
        ]);
      })
      .catch((err) => {
        toast.error(err);
      });
  }
  if (!templateInfo) return null;

  const templateFields = templateModel
    ? Object.keys(templateModel)
        .map((m) => ({
          name: m,
          label: m,
          value: templateModel[m],
          required: requiredTemplateItems.includes(m),
        }))
        .filter(
          (f) =>
            !nonEditableFields.includes(f.name) && f.name.indexOf("img_") === -1
        )
    : [];

  const imageFields = templateModel
    ? Object.keys(templateModel)
        .map((m) => ({
          name: m,
          value: templateModel[m] === `${[m]}_Value` ? "" : templateModel[m],
          required: requiredTemplateItems.includes(m),
        }))
        .filter((f) => f.name.indexOf("img_") > -1)
    : [];

  return (
    <div>
      <Card>
        <Grid>
          <Grid.Col span={{ base: 12, md: 6 }} id="fields">
            <Skeleton visible={templateInfo ? false : true}>
              <Flex
                direction="column"
                style={{
                  height: "100%",
                  width: "100%",
                }}
              >
                <Box style={{ flexGrow: 1 }}>
                  <Tabs defaultValue="text">
                    {imageFields.length > 0 && (
                      <Tabs.List mb="md">
                        <Tabs.Tab
                          value="text"
                          leftSection={<IconForms size={18} />}
                        >
                          Template Text
                        </Tabs.Tab>
                        <Tabs.Tab
                          value="images"
                          leftSection={<IconPhoto size={18} />}
                        >
                          Template Images
                        </Tabs.Tab>
                      </Tabs.List>
                    )}
                    <Tabs.Panel value="text">
                      <Text fw={600}>The Basics</Text>
                      <TextInput
                        label="Subject"
                        disabled={!editable}
                        value={templateModel.subject}
                        required
                        onChange={(e) =>
                          setTemplateModel({
                            ...templateModel,
                            subject: e.target.value,
                          })
                        }
                      />
                      <TextInput
                        label="Reply to"
                        disabled={!editable}
                        value={templateModel.reply_to}
                        required
                        onChange={(e) =>
                          setTemplateModel({
                            ...templateModel,
                            reply_to: e.target.value,
                          })
                        }
                      />
                      <TextInput
                        label="Sender Name"
                        disabled={!editable}
                        value={templateModel.sender_name}
                        required
                        onChange={(e) =>
                          setTemplateModel({
                            ...templateModel,
                            sender_name: e.target.value,
                          })
                        }
                      />
                      {templateFields.length > 0 && (
                        <>
                          <Divider mt="lg" mb="lg" />
                          <Flex gap="xs">
                            <Text fw={600}>Template variables</Text>
                            <TemplateGuide
                              reqData={{
                                postmark_template_alias: templateAlias,
                                bulk_communicator_template_id: serverTemplateId,
                              }}
                            />
                          </Flex>
                          <TemplateVariables
                            fields={templateFields}
                            editable={editable}
                            constants={constants}
                            onChange={(e, validateAfter = false) => {
                              if (validateAfter) {
                                validateTemplate(e);
                              } else {
                                setTemplateModel(e);
                              }
                            }}
                            templateModel={templateModel}
                          />
                        </>
                      )}
                    </Tabs.Panel>
                    <Tabs.Panel value="images">
                      <Flex gap="xs">
                        <Text fw={600}>Template images</Text>
                        <TemplateGuide
                          reqData={{
                            postmark_template_alias: templateAlias,
                            bulk_communicator_template_id: serverTemplateId,
                          }}
                        />
                      </Flex>
                      <Images
                        editable={editable}
                        fields={imageFields}
                        // fetchData={validateTemplate}
                        fetchData={() => {
                          // window.location.reload();
                          fetchServerTemplate();
                        }}
                        templateId={serverTemplateId}
                      />
                    </Tabs.Panel>
                  </Tabs>
                </Box>
                <>
                  <Divider mt="lg" />
                  <Flex gap="xs" mt="sm">
                    {editable && (
                      <Button
                        onClick={() => validateTemplate()}
                        loading={saving}
                        // disabled={submitDisabled}
                        style={{
                          width: "fit-content",
                        }}
                      >
                        Update
                      </Button>
                    )}
                    <SendTestButton
                      templateId={serverTemplateId}
                      disabled={
                        !templateModel.subject || !templateModel.reply_to
                      }
                    />
                  </Flex>
                </>
              </Flex>
            </Skeleton>
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6 }}>
            {templateAlias && (
              <Box mt="lg" mb="lg">
                <TemplatePreview
                  loading={templateLoading}
                  templateInfo={templateInfo}
                  templateModel={templateModel}
                  html={
                    templateInfo.validation_response.HtmlBody.RenderedContent
                  }
                />
              </Box>
            )}
            <Text c="dimmed" size="xs" align="right">
              {templateAlias}
            </Text>
          </Grid.Col>
        </Grid>
      </Card>
    </div>
  );
}

EmailComposition.deafultProps = {
  editable: true,
  requiredTemplateItems: [],
  savedDataModel: {},
};

export const TemplatePreview = ({ html, loading }) => {
  if (loading || !html) return <Loader size="xs" />;

  const [height, setHeight] = useState("500px");
  useEffect(() => {
    const fieldCol = document.getElementById("fields");
    if (fieldCol) {
      setHeight(`${fieldCol.clientHeight}px`);
    }
  }, [JSON.stringify(html)]);

  return (
    <iframe
      width="100%"
      height={height}
      sandbox="allow-same-origin"
      srcDoc={html}
      style={{
        border: "2px solid var(--mantine-color-dark-8)",
        background: "transparent",
      }}
    />
  );
};

function SendTestButton({ templateId, disabled }) {
  const [isOpen, setOpen] = useState(false);
  const [value, setValue] = useState("");
  const [loading, setLoading] = useState(false);

  const managerInfo = useSelector((state) => state.manager);
  const adminInfo = useSelector((state) => state.admin);

  const email = managerInfo
    ? managerInfo.email
    : adminInfo
    ? adminInfo.email
    : "";

  useEffect(() => {
    if (email) {
      setValue(email);
    }
  }, []);

  function onSubmit() {
    const req = {
      template_id: templateId,
      email: value,
    };

    setLoading(true);

    axios
      .post(`/bulk-communicators/templates/send-test-email/`, req)
      .then(() => {
        toast.success("Sent");
        setLoading(false);
        onClose();
      })
      .catch((err) => {
        toast.error(err);
        setLoading(false);
      });
  }

  function onClose() {
    setOpen(false);
    setLoading(false);
  }

  return (
    <>
      <Button color="teal" disabled={disabled} onClick={() => setOpen(true)}>
        Send Test
      </Button>
      <Modal opened={isOpen} onClose={onClose}>
        <Text mb="xs" fw={600}>
          Send a test email to the following address:
        </Text>
        <TextInput
          // label="Email"
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />
        <Button
          fullWidth
          mt="xs"
          onClick={onSubmit}
          loading={loading}
          disabled={!value || !emailValidation.test(value)}
        >
          Send
        </Button>
      </Modal>
    </>
  );
}

const Images = ({ fields = [], templateId, fetchData, editable = true }) => {
  const [loading, setLoading] = useState(false);
  const [visibleFields, setVisibleFields] = useState([]);

  const alwaysShowNames = ["img_1"];

  useEffect(() => {
    setup();
  }, []);

  function setup() {
    setVisibleFields(fields.filter((f) => f.value).map((m) => m.name));
  }

  function onImageUpload(img, keyName) {
    const formData = new FormData();
    setLoading(true);
    formData.append("template_image", img);
    formData.append("data_model_key", keyName);
    formData.append("template_id", templateId);

    axios
      .post(`/bulk-communicators/templates/add-image/`, formData)
      .then(() => {
        fetchData();
        setLoading(false);
      })
      .catch((err) => {
        toast.error(err);
        setLoading(false);
      });
  }

  function onImageRemove(keyName) {
    setLoading(true);
    const req = {
      data_model_key: keyName,
      template_id: templateId,
    };

    axios
      .post(`/bulk-communicators/templates/remove-image/`, req)
      .then(() => {
        fetchData();
        setLoading(false);
      })
      .catch((err) => {
        toast.error(err);
        setLoading(false);
      });
  }

  function onLibrarySelection(assetId, keyName) {
    setLoading(true);
    const req = {
      asset_id: assetId,
      data_model_key: keyName,
      template_id: templateId,
      variety: 13,
    };

    axios
      .post(`/replicate-library-asset/`, req)
      .then(({ data }) => {
        fetchData();
        setLoading(false);
      })
      .catch((err) => {
        fetchData();
        toast.error(err);
        setLoading(false);
      });
  }

  if (!fields.length) return null;

  const addableFields = fields.filter((f) => !visibleFields.includes(f.name));
  const addedFields = fields.filter((f) => visibleFields.includes(f.name));

  return (
    <>
      <LoadingOverlay visible={loading} />
      {addableFields.length > 0 && editable && (
        <div>
          <Text size="xs" mb="xs">
            Add more to this template
          </Text>
          <Flex gap="xs" wrap="wrap" mb="xs" mt="xs">
            {addableFields.map((m, i) => (
              <Button
                key={i}
                style={{ width: "fit-content" }}
                size="xs"
                variant="light"
                color="gray"
                onClick={(e) => setVisibleFields([...visibleFields, m.name])}
              >
                {formatLabel(m.name)}
              </Button>
            ))}
          </Flex>
          {addedFields.length > 0 && <Divider mt="lg" mb="lg" />}
        </div>
      )}
      {addedFields.map((m, i) => (
        <Box key={i}>
          {!m.value ? (
            <>
              <Text fw={600} mb="xs" size="sm">
                {formatLabel(m.name)}
                {m.required ? (
                  <span style={{ color: "var(--mantine-color-red-5" }}> *</span>
                ) : (
                  ""
                )}
              </Text>
              <Flex gap="xs">
                {/* <FileButton
                  // label={m.name}
                  placeholder="Upload image"
                  name=""
                  accept="image/*"
                  disabled={!editable}
                  onChange={(e) => onImageUpload(e, m.name)}
                  // mb={i === fields.length - 1 ? 0 : "xs"}
                  mb="xs"
                /> */}
                <FileButton
                  style={{ width: "auto" }}
                  onChange={(e) => onImageUpload(e, m.name)}
                >
                  {(props) => (
                    <Button
                      {...props}
                      variant="light"
                      fullWidth
                      color={"green"}
                      size="xs"
                      leftSection={<IconFileUpload size={20} />}
                    >
                      {`Upload image`}
                    </Button>
                  )}
                </FileButton>
                <AssetLibraryPicker
                  onClose={() => true}
                  onSelect={(assetId) => onLibrarySelection(assetId, m.name)}
                  reqData={{
                    variety: [6],
                  }}
                />
                <Button
                  size="xs"
                  variant="light"
                  color="gray"
                  leftSection={<IconTrash size={16} />}
                  onClick={() =>
                    setVisibleFields(
                      [...visibleFields].filter((f) => f !== m.name)
                    )
                  }
                >
                  Remove
                </Button>
              </Flex>
              {i < visibleFields.length - 1 && <Divider mt="lg" mb="lg" />}
            </>
          ) : (
            <Box key={i} mb={i === addedFields.length - 1 ? 0 : "xs"}>
              <Flex gap="xs" align="center">
                {editable && (
                  <ActionIcon
                    size="xs"
                    color="gray"
                    variant="subtle"
                    radius="xl"
                    onClick={() => onImageRemove(m.name)}
                  >
                    <IconTrash />
                  </ActionIcon>
                )}
                <StyledImage src={m.value} alt={m.name} />
                <Text size="sm">
                  <Anchor target="_blank" href={m.value}>
                    {formatLabel(m.name)}
                  </Anchor>
                </Text>
              </Flex>
              {i < addedFields.length - 1 && <Divider mt="lg" mb="lg" />}
            </Box>
          )}
        </Box>
      ))}
    </>
  );
};

const StyledImage = styled.img`
  width: 80px;
  height: 80px;
  object-fit: cover;
  border: 2px solid var(--mantine-color-dark-8);
`;

function formatLabel(oldLabel = "") {
  return oldLabel.split("_").join(" ");
}

const RteWrapper = styled.div`
  div.mantine-RichTextEditor-toolbar {
    --rte-sticky-offset: 0 !important;
  }
`;

const RtePreview = styled.div`
  background: var(--mantine-color-gray-1);
  border: calc(0.0625rem * var(--mantine-scale)) solid
    var(--mantine-color-gray-3);
  padding: 10px;
  color: var(--mantine-color-gray-6);
  opacity: 0.6;
  cursor: not-allowed;
  border-radius: var(--mantine-radius-sm);

  p:first-of-type {
    padding-top: 0 !important;
    margin-top: 0 !important;
  }
`;

const TemplateVariables = ({
  fields,
  editable,
  constants,
  onChange,
  templateModel,
}) => {
  const [visibleFields, setVisibleFields] = useState([]);

  const alwaysShowNames = [
    "title_1",
    "body_1",
    "button_url_1",
    "button_text_1",
  ];

  useEffect(() => {
    setup();
  }, []);

  function setup() {
    const fieldsWithNonDefaultValues = fields
      .filter((f) => !alwaysShowNames.includes(f.name))
      .filter(
        (f) =>
          f.value.indexOf("_Value") === -1 &&
          f.value !== "" &&
          f.value !== "<p></p>"
      )
      .map((m) => m.name);
    setVisibleFields([...alwaysShowNames, ...fieldsWithNonDefaultValues]);
  }

  function onFieldRemove(fieldName) {
    setVisibleFields([...visibleFields].filter((f) => f !== fieldName));
    onChange(
      {
        ...templateModel,
        [fieldName]: "",
      },
      true
    );
  }

  const addableFields = fields.filter((f) => !visibleFields.includes(f.name));
  const addedFields = fields.filter((f) => visibleFields.includes(f.name));

  return (
    <div>
      {addableFields.length > 0 && editable && (
        <div>
          <Text size="xs" mb="xs">
            Add more to this template
          </Text>
          <Flex gap="xs" wrap="wrap" mb="xs" mt="xs">
            {addableFields.map((m, i) => (
              <Button
                key={i}
                style={{ width: "fit-content" }}
                size="xs"
                variant="light"
                color="gray"
                onClick={(e) => setVisibleFields([...visibleFields, m.name])}
              >
                {formatLabel(m.label)}
              </Button>
            ))}
          </Flex>
          <Divider mt="lg" mb="lg" />
        </div>
      )}
      <div
        style={{ maxHeight: "500px", overflowY: "auto", paddingRight: "5px" }}
      >
        {addedFields.map((field, i) => (
          <div key={i}>
            {field.name === "recipient_name" ? (
              <Select
                label="recipient_name"
                disabled={!editable}
                placeholder="Select one..."
                value={field.value}
                data={constants.recipient_names}
                onChange={(e) =>
                  onChange({
                    ...templateModel,
                    [field.name]: e,
                  })
                }
              />
            ) : field.name === "greeting" ? (
              <Select
                label="greeting"
                placeholder="Select one..."
                disabled={!editable}
                value={field.value}
                data={constants.greetings}
                onChange={(e) =>
                  onChange({
                    ...templateModel,
                    [field.name]: e,
                  })
                }
              />
            ) : field.name === "closing" ? (
              <Select
                label="closing"
                placeholder="Select one..."
                disabled={!editable}
                value={field.value}
                data={constants.closings}
                onChange={(e) =>
                  onChange({
                    ...templateModel,
                    [field.name]: e,
                  })
                }
              />
            ) : field.name.indexOf("body") > -1 ? (
              <Input.Wrapper
                label={formatLabel(field.label)}
                required={field.required}
              >
                {!editable ? (
                  <RtePreview>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: field.value,
                      }}
                    />
                  </RtePreview>
                ) : (
                  <RteWrapper>
                    <Rte
                      value={field.value}
                      onChange={(e) =>
                        onChange({
                          ...templateModel,
                          [field.name]: e,
                        })
                      }
                    />
                  </RteWrapper>
                )}
              </Input.Wrapper>
            ) : field.name.indexOf("button_url") > -1 ? (
              <TextInput
                key={i}
                // label={`${field.label} (must include https://)`}
                label={
                  <label>
                    {formatLabel(field.label)} (must include https://){" "}
                    {field.value && (
                      <a href={field.value} target="_blank">
                        preview
                      </a>
                    )}
                  </label>
                }
                name={field.name}
                disabled={!editable || ["entity_name"].includes(field.name)}
                // error={
                //   !editable
                //     ? ""
                //     : field.value
                //     ? new RegExp(urlValidation).test(
                //         field.value
                //       )
                //       ? ""
                //       : "This will not work!"
                //     : ""
                // }
                onChange={(e) =>
                  onChange({
                    ...templateModel,
                    [field.name]: e.target.value,
                  })
                }
                value={field.value}
              />
            ) : (
              <TextInput
                key={i}
                label={formatLabel(field.label)}
                name={field.name}
                required={field.required}
                disabled={!editable || ["entity_name"].includes(field.name)}
                onChange={(e) =>
                  onChange({
                    ...templateModel,
                    [field.name]: e.target.value,
                  })
                }
                value={field.value}
              />
            )}
            {!alwaysShowNames.includes(field.name) && editable && (
              <Flex justify="end" mt="xs" style={{ width: "100%" }}>
                <Button
                  key={i}
                  style={{ width: "fit-content" }}
                  size="xs"
                  leftSection={<IconTrash size={16} />}
                  variant="subtle"
                  color="gray"
                  onClick={() => onFieldRemove(field.name)}
                >
                  remove field
                </Button>
              </Flex>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

const TemplateGuide = ({ reqData }) => {
  const [isOpen, setOpen] = useState(false);
  const [templateInfo, setTemplateInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!isOpen) return;
    fetchPreview();
  }, [isOpen]);

  function fetchPreview() {
    const req = {
      ...reqData,
    };

    setLoading(true);
    setTemplateInfo(null);

    axios
      .post(`/bulk-communicators/templates/preview-template/`, req)
      .then(({ data }) => {
        setLoading(false);
        setTemplateInfo(data.response[0]);
      })
      .catch((err) => {
        setError(err);
        setLoading(false);
        setTemplateInfo(null);
      });
  }

  function onClose() {
    setOpen(false);
    setError(null);
    setLoading(false);
  }

  return (
    <>
      <Button
        variant="subtle"
        color="gray"
        size="xs"
        leftSection={<IconTemplate size={16} />}
        onClick={() => setOpen(true)}
      >
        View Guide
      </Button>
      <Modal onClose={onClose} opened={isOpen} size="lg">
        <TemplatePreview
          loading={loading}
          html={templateInfo ? templateInfo.HtmlBody.RenderedContent : null}
        />
        {error && <Text>{error}</Text>}
      </Modal>
    </>
  );
};
