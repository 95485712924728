import React, { useState, useEffect } from "react";
import axios from "axios";
import { Grid } from "@mantine/core";

import { PrizeItem } from "./";
import { EmptyMessage } from "@components/shared";

export default function PrizeList({
  contestId,
  isAuction,
  isPrizePool,
  locationId,
  minimal,
  organizationId,
  refresh,
  onModalClose,
  showPrizeEdit = true,
}) {
  const [prizes, setPrizes] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchPrizes();
  }, [refresh]);

  function fetchPrizes() {
    setLoading(true);
    axios
      .get(`/contests/${contestId}/retrieve-prizes/`)
      .then(({ data }) => {
        setPrizes(data.response);
        setLoading(false);
      })
      .catch((err) => {
        setPrizes([]);
        setLoading(false);
      });
  }

  return (
    <div>
      {!loading && prizes.length === 0 && <EmptyMessage />}
      <Grid
        style={{
          borderRadius: "5px",
        }}
      >
        {prizes.map((p, i) => (
          <Grid.Col span={{ base: 12, md: minimal ? 12 : 6 }} key={p.id}>
            <PrizeItem
              key={p.id}
              organizationId={organizationId}
              locationId={locationId}
              prizeData={p}
              id={p.id}
              onUpdate={fetchPrizes}
              onModalClose={onModalClose}
              showDuplicate={!isAuction}
              showDelete={
                // (isPrizePool || isAuction) && prizes.length === 1 ? false : true
                prizes.length === 1 ? false : true
              }
              showPrizeEdit={showPrizeEdit}
            />
          </Grid.Col>
        ))}
      </Grid>
    </div>
  );
}
