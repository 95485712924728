import React, { useState, useEffect } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import {
  Badge,
  Card,
  Group,
  Grid,
  TextInput,
  Text,
  Button,
  Space,
  useMantineTheme,
} from "@mantine/core";
import { IconHandRock, IconChevronsLeft } from "@tabler/icons-react";
import styled from "styled-components";
import { shallow } from "zustand/shallow";
import { useTranslation } from "react-i18next";

import CheckInButton from "./CheckInButton";
import EffortSlider from "./EffortSlider";
import UserEffortView from "./UserEffortView";
import ViewTitle from "./ViewTitle";
import { ImageSlider } from "@components/shared";
import { iconSize } from "./helpers";
import { Gauge } from "@components/DataViz";
import useAppStore from "./microsite-store";

import { effortRecurringEngagement } from "@components/Effort/helpers";

export default function UserDashboard({ fetchUser }) {
  const theme = useMantineTheme();
  const { t } = useTranslation();

  const {
    appConfig,
    coords,
    colors,
    effortId,
    entityInfo,
    user,
    selectedEffortId,
    setSelectedEffortId,
  } = useAppStore(
    (state) => ({
      appConfig: state.appConfig,
      coords: state.coords,
      colors: state.colors,
      effortId: state.effortId,
      entityInfo: state.entityInfo,
      selectedEffortId: state.userSelectedEffortId,
      setSelectedEffortId: state.setUserSelectedEffortId,
      user: state.user,
    }),
    shallow
  );

  const [contestSlideIndex, setContestSlideIndex] = useState(0);
  const [noLocation, setNoLocation] = useState(false);

  const efforts = user.campaign_efforts.filter((f) => f.entered);

  useEffect(() => {
    if (!entityInfo) return;
  }, [JSON.stringify(entityInfo)]);

  useEffect(() => {
    if (
      efforts.length === 1 &&
      entityInfo.campaign_effort_id &&
      efforts.find((f) => f.id === entityInfo.campaign_effort_id)
    ) {
      setSelectedEffortId(efforts[0].id);
    }
  }, [JSON.stringify(user)]);

  const selectedEffort = efforts
    ? efforts.find((f) => f.id === selectedEffortId)
    : null;

  const hasEnteredRecurringEffort = efforts.find(
    (f) =>
      f.entered && f.recurring_engagement === effortRecurringEngagement.true
  )
    ? true
    : false;

  if (!selectedEffort) {
    return (
      <div>
        <ViewTitle
          icon={<IconHandRock size={iconSize} />}
          title={
            user.first_name
              ? `${t("user_welcome_title")}, ${user.first_name}!`
              : `${t("user_welcome_title")}!`
          }
          // title={t("user_welcome_title", { name: user.first_name })}
          subtitle={t("user_welcome_subtitle")}
        />
        {!effortId && entityInfo.use_checkins && hasEnteredRecurringEffort && (
          <Group justify="center" mb="xl" style={{ marginTop: "-1em" }}>
            <CheckInButton onSuccess={fetchUser} />
          </Group>
        )}
        {efforts.length === 0 && (
          <Text align="center" c="white">
            {t("user_no_campaigns_message")}
          </Text>
        )}
        <EffortSlider
          colors={colors}
          efforts={efforts.map((m) => ({
            ...m,
            children: (
              <React.Fragment>
                {m.recurring_engagement === effortRecurringEngagement.false ? (
                  <Badge variant="light" size="lg">
                    {m.is_point_award ? (
                      <React.Fragment>Points: {m.point_total}</React.Fragment>
                    ) : (
                      <React.Fragment>
                        {t("entries")}:{" "}
                        {m.contests.reduce((acc, cur) => (acc += cur.count), 0)}
                      </React.Fragment>
                    )}
                  </Badge>
                ) : (
                  <Button onClick={() => setSelectedEffortId(m.id)}>
                    {t("view_campaign_button")}
                  </Button>
                )}
              </React.Fragment>
            ),
          }))}
        />
      </div>
    );
  }

  const effortConfig = selectedEffort.draft_configuration;
  const assets = effortConfig.assets
    ? effortConfig.assets
        .map((m) => ({
          ...m,
          asset: selectedEffort.assets.find((f) => f.id === m.id),
        }))
        .filter((f) => f.asset)
    : [].filter((f) => f.asset);
  const sliderImages = assets.filter((f) => f.placement === 2);
  const contests = [...selectedEffort.contests].sort(
    (a, b) => b.retail_value_in_pennies - a.retail_value_in_pennies
  );

  // contest being displayed in slider
  const activeContest = contests[contestSlideIndex];

  return (
    <div>
      <Group justify="center" align="center">
        <Button
          mt="lg"
          variant="subtle"
          color="gray"
          leftSection={<IconChevronsLeft />}
          onClick={() => setSelectedEffortId(null)}
          mb="lg"
        >
          {t("back_button")}
        </Button>
      </Group>
      {sliderImages.length > 0 && (
        <ImageSlider
          items={[
            ...sliderImages.map((m) => ({
              src: m.asset.filename_url,
              key: m.id,
              external_link_url: m.asset.external_link_url,
            })),
          ]}
        />
      )}
      <Space h="sm" />
      <ViewTitle
        title={selectedEffort.title}
        subtitle={entityInfo.use_keywords ? t("user_effort_view_subtitle") : ""}
        marginBottom={"1em"}
        order={1}
      />
      <CheckinGauge>
        {coords && entityInfo.use_checkins && (
          <BoxWrapper hide={noLocation}>
            <CheckInButton
              onSuccess={fetchUser}
              onNoLocation={() => setNoLocation(true)}
            />
          </BoxWrapper>
        )}
        {appConfig.components && activeContest && (
          <BoxWrapper>
            <GaugeWrapper
              variant={
                appConfig.components ? appConfig.components.gauge_variant : null
              }
            >
              <Gauge
                value={activeContest.count}
                domain={[0, activeContest.total_entries_allowed]}
                variant={
                  appConfig.components
                    ? appConfig.components.gauge_variant
                    : null
                }
                fillColor={
                  theme.colors[colors.base]
                    ? theme.colors[colors.base][6]
                    : null
                }
              />
              {activeContest && (
                <Text size="xs" c="dimmed" align="center">
                  {activeContest.name}
                  <br />
                  {activeContest.count} out of{" "}
                  {activeContest.total_entries_allowed}
                </Text>
              )}
            </GaugeWrapper>
          </BoxWrapper>
        )}
      </CheckinGauge>
      <Space h="xl" />
      <UserEffortView
        effort={selectedEffort}
        fetchData={fetchUser}
        contests={contests}
        onSlideChange={(e) => setContestSlideIndex(e)}
      />
      <Group justify="center" align="center">
        <Button
          mt="lg"
          variant="subtle"
          // fullWidth
          color="gray"
          onClick={() => setSelectedEffortId(null)}
        >
          {t("view_my_campaigns_button")}
        </Button>
      </Group>
    </div>
  );
}

const CampaignItem = ({ count, title }) => {
  return <CardDisplayItem name={title} count={count} />;
};

const EffortItem = ({ contests = [], title, id, fetchUser }) => {
  return (
    <div>
      <h2>{title}</h2>
      <KeywordForm onSuccess={() => fetchUser()} effortId={id} />
      <Grid mt="lg">
        {contests.map((c) => (
          <Grid.Col span={{ base: 6 }} key={c.id}>
            <ContestItem
              name={c.name}
              count={c.count}
              effortId={id}
              fetchUser={fetchUser}
            />
          </Grid.Col>
        ))}
      </Grid>
    </div>
  );
};
const ContestItem = ({ name, count }) => {
  return <CardDisplayItem name={name} count={count} />;
};

const KeywordForm = ({ onSuccess, effortId }) => {
  const [value, setValue] = useState("");
  const [loading, setLoading] = useState(false);

  const user = useAppStore((state) => state.user);
  const entityInfo = useAppStore((state) => state.entityInfo);
  const coords = useAppStore((state) => state.coords);

  function onSubmit() {
    const req = {
      keyword: value,
      campaign_effort_id: effortId,
      user_id: user.id,
    };

    if (entityInfo.location) req.location_id = entityInfo.location.id;
    if (coords) {
      req.latitude = coords.latitude;
      req.longitude = coords.longitude;
    }

    setLoading(true);

    axios
      .post(`/microsite/keyword-submit/`, req)
      .then(() => {
        setLoading(false);
        setValue("");
        onSuccess();
        toast.success("Entered!");
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  return (
    <div>
      <TextInput
        placeholder="Keyword"
        value={value}
        onChange={(e) => setValue(e.target.value)}
      />
      <Button
        disabled={!value || loading}
        onClick={onSubmit}
        loading={loading}
        mt="md"
        fullWidth
      >
        Submit
      </Button>
    </div>
  );
};

const CountBadge = ({ count }) => (
  <Badge variant="light" mt="sm">
    {count} entr{count > 1 ? "ies" : "y"}
  </Badge>
);

const CardDisplayItem = ({ name, count }) => (
  <Card>
    <Text fw={600} size="lg">
      {name}
    </Text>
    <CountBadge count={count} />
  </Card>
);

const GaugeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  svg {
    margin-bottom: ${(props) => (props.variant === 2 ? "25px" : "10px")};
  }
`;

const BoxWrapper = styled.div`
  display: ${(props) => (props.hide ? "none" : "flex")};
  justify-content: center;
  width: ${(props) => (props.hide ? "0%" : "100%")};
`;

const CheckinGauge = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
