import axios from "axios";
import React, { useState, useEffect } from "react";
import { useParams, Route, Routes, Link } from "react-router-dom";
import { Flex, Anchor, Button, Grid, Tabs, Divider, Card } from "@mantine/core";
import { useSelector } from "react-redux";
import { IconForms, IconQrcode } from "@tabler/icons-react";

import {
  ErrorView,
  MicrositeSettings,
  QrCodeGenerator,
  PageTitle,
  Subnav,
} from "@components/shared";
import {
  CampaignForm,
  CampaignParticipants,
  CampaignReadOnly,
  CampaignStatus,
} from "@components/Campaign";
import { EffortManagement } from "@components/Effort";
import { KeywordManagement } from "@components/Keyword";
import { ContestManagement } from "@components/Contest";
import { EntityConfigSettings } from "@components/EntityConfig";
import { EntityEmailTemplates } from "@components/EmailServer";

import entityIcon from "@util/entityIcon";
import entityColor from "@util/entityColor";

export default function CampaignDetail() {
  const [info, setInfo] = useState(null);
  const [error, setError] = useState(null);
  const params = useParams();
  const { id } = params;

  const managerInfo = useSelector((state) => state.manager);
  const isLocationManager =
    managerInfo && managerInfo.location_id ? true : false;

  const isManager = managerInfo;

  useEffect(() => {
    fetchData();
  }, []);

  function fetchData() {
    axios
      .get(`/campaigns/${id}/`)
      .then(({ data }) => {
        setInfo(data.response[0]);
      })
      .catch((err) => {
        setError(err);
        setInfo(null);
      });
  }

  if (error) {
    return <ErrorView text={error} />;
  }

  if (!info) return null;

  if (!info.edit) {
    return (
      <div>
        <PageTitle
          title={info.title}
          subtitle={`Campaign`}
          icon={entityIcon.campaign()}
          iconColor={entityColor.campaign}
        />
        <CampaignReadOnly campaignInfo={info} />
      </div>
    );
  }

  const Detail = () => {
    return (
      <React.Fragment>
        <Grid>
          <Grid.Col span={{ base: 12, md: 9 }}>
            <Tabs defaultValue="form">
              {/* <Tabs.List mb="lg">
                <Tabs.Tab icon={<IconForms />} value="form">
                  Form
                </Tabs.Tab>
                <Tabs.Tab icon={<IconQrcode />} value="qr">
                  QR Code
                </Tabs.Tab>
              </Tabs.List> */}
              <Tabs.Panel value="form">
                <Card>
                  <CampaignForm
                    allowManagerEdit={info.allow_manager_edit}
                    apiContext={info.api_context}
                    apiProgram={info.api_program}
                    endDate={info.end_date}
                    endTime={info.end_time}
                    id={id}
                    internalName={info.internal_name}
                    micrositeUrl={info.microsite_url}
                    onSuccess={fetchData}
                    startDate={info.start_date}
                    startTime={info.start_time}
                    thirdPartyApiEnabled={info.third_party_api_enabled}
                    timezone={info.timezone}
                    title={info.title}
                  />
                </Card>
                <Card mt="lg">
                  <EntityConfigSettings
                    campaignId={id}
                    useKeywords={info.use_keywords}
                    useCheckins={info.use_checkins}
                    onUpdate={fetchData}
                  />
                </Card>
              </Tabs.Panel>
              <Tabs.Panel value="qr">
                <Card>
                  <QrCodeGenerator
                    text={`${import.meta.env.VITE_DOMAIN}/entry/${
                      info.microsite_url
                    }`}
                  />
                </Card>
              </Tabs.Panel>
            </Tabs>
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 3 }}>
            <CampaignStatus
              fetchData={fetchData}
              status={info.status}
              liveStatus={info.live_status}
              isLive={info.is_live}
              campaignId={id}
            />
          </Grid.Col>
        </Grid>
      </React.Fragment>
    );
  };

  return (
    <div>
      <PageTitle
        title={info.title}
        subtitle={
          <React.Fragment>
            Campaign {info.author_string ? `${info.author_string}` : ""}
          </React.Fragment>
        }
        icon={entityIcon.campaign()}
        iconColor={entityColor.campaign}
        style={{ marginBottom: managerInfo ? "1em" : 0 }}
      />
      {!managerInfo && (
        <React.Fragment>
          {info.location_id && (
            <PageTitle
              icon={entityIcon.location()}
              iconColor={entityColor.location}
              subtitle={
                <Anchor
                  component={Link}
                  style={{ color: "inherit" }}
                  to={`/locations/${info.location_id}`}
                  target="_blank"
                >
                  {info.location_name}
                </Anchor>
              }
            />
          )}
          {info.organization_id && (
            <PageTitle
              icon={entityIcon.organization()}
              iconColor={entityColor.organization}
              subtitle={
                <Anchor
                  component={Link}
                  style={{ color: "inherit" }}
                  to={`/organizations/${info.organization_id}`}
                  target="_blank"
                >
                  {info.organization_name}
                </Anchor>
              }
            />
          )}
        </React.Fragment>
      )}
      <Flex>
        <Subnav
          buttonColor={entityColor.campaign}
          links={[
            { to: `/campaigns/${id}`, text: "Detail" },
            { to: `/campaigns/${id}/efforts`, text: "Efforts" },
            {
              to: `/campaigns/${id}/participants`,
              text: "Participants",
            },
            { to: `/campaigns/${id}/keywords`, text: "Keywords" },
            { to: `/campaigns/${id}/contests`, text: "Contests" },
            { to: `/campaigns/${id}/config`, text: "Look & Feel" },
            { to: `/campaigns/${id}/email`, text: "Email" },
          ]
            .filter((f) => (isManager ? !["Email"].includes(f.text) : true))
            .filter((f) =>
              isManager || info.location_id
                ? !["Participants"].includes(f.text)
                : true
            )}
        />
        {info.microsite_url && (
          <Button
            href={`${import.meta.env.VITE_DOMAIN}/entry/${info.microsite_url}`}
            component="a"
            target="_blank"
            variant="subtle"
            size="xs"
            mb="lg"
            color="gray"
          >
            Preview
          </Button>
        )}
      </Flex>
      <Routes>
        <Route exact path="/" element={<Detail />} />
        <Route
          path="efforts"
          element={
            <React.Fragment>
              <EffortManagement campaignId={id} />
            </React.Fragment>
          }
        />
        {!isLocationManager && (
          <Route
            path="participants"
            element={
              <React.Fragment>
                <CampaignParticipants
                  organizationId={info.organization_id}
                  campaignId={id}
                />
              </React.Fragment>
            }
          />
        )}
        <Route
          path="keywords"
          element={
            <React.Fragment>
              <KeywordManagement
                minStartDate={info.start_date}
                maxEndDate={info.end_date}
                campaignId={id}
              />
            </React.Fragment>
          }
        />
        <Route
          path="contests"
          element={
            <React.Fragment>
              <ContestManagement campaignId={id} endDate={info.end_date} />
            </React.Fragment>
          }
        />
        {!isManager && (
          <Route
            path="email"
            element={
              <EntityEmailTemplates
                postmarkTemplateNames={info.postmark_template_names}
                campaignId={id}
                templates={[
                  {
                    name: "Verify Email",
                    field_name: "verify-email",
                    value: info.verification_template,
                  },
                ]}
                fetchData={fetchData}
              />
            }
          />
        )}
        <Route
          path="config"
          element={
            <React.Fragment>
              <Grid>
                <Grid.Col span={{ base: 12, md: 9 }}>
                  <Card>
                    <MicrositeSettings
                      campaignId={id}
                      locationId={info.location_id}
                      onUpdate={fetchData}
                    />
                  </Card>
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 3 }}>
                  <CampaignStatus
                    fetchData={fetchData}
                    status={info.status}
                    liveStatus={info.live_status}
                    isLive={info.is_live}
                    campaignId={id}
                    hasChangesToPublish={info.has_app_config_to_publish}
                  />
                </Grid.Col>
              </Grid>
            </React.Fragment>
          }
        />
      </Routes>
    </div>
  );
}
