import React, { useState, useEffect } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import {
  Button,
  FileButton,
  Divider,
  Modal,
  Radio,
  Group,
  Text,
} from "@mantine/core";
import { IconFileUpload } from "@tabler/icons-react";

import entityColor from "@util/entityColor";
import entityIcon from "@util/entityIcon";

const libraryAssetButtons = [
  {
    text: "Contest",
    library_variety: 1,
    variety: 5,
    color: entityColor.contest,
    icon: entityIcon.contest(),
  },
  {
    text: "Campaign",
    library_variety: 2,
    variety: 3,
    color: entityColor.campaign,
    icon: entityIcon.campaign(),
  },
  {
    text: "Effort",
    library_variety: 3,
    variety: 2,
    color: entityColor.effort,
    icon: entityIcon.effort(),
  },
  {
    text: "General",
    library_variety: 4,
    variety: 10,
    color: entityColor.general,
    icon: entityIcon.general(),
  },
  {
    text: "Trouble Ticket",
    library_variety: 5,
    variety: 7,
    color: entityColor.ticket,
    icon: entityIcon.ticket(),
  },
  {
    text: "Email Template",
    library_variety: 6,
    variety: 13,
    // color: entityColor.ticket,
    // icon: entityIcon.ticket(),
  },
].map((m, i) => ({
  ...m,
  key: i,
}));

export default function AssetUpload({ reqData, onSuccess, library }) {
  const [show, setShow] = useState(true);
  const [open, setOpen] = useState(false);
  const [uploadProps, setUploadProps] = useState({
    text: "",
    library_variety: null,
    variety: null,
    color: "",
  });

  useEffect(() => {
    if (!open)
      setUploadProps({
        text: "",
        library_variety: null,
        variety: null,
        color: "",
      });
  }, [open]);

  const assetVarieties = [5, 3, 2, 10, 7];

  function onUpload(file, variety = null) {
    const formData = new FormData();
    formData.append("tempfile", file);

    if (library && uploadProps.library_variety) {
      formData.append("library_variety", uploadProps.library_variety);
      formData.append("variety", uploadProps.variety);
      // formData.append(
      //   "variety",
      //   assetVarieties[uploadProps.library_variety - 1]
      // );
    }

    if (!library && reqData.contest_id) {
      formData.append("variety", 5);
    }
    if (!library && reqData.campaign_id) {
      formData.append("variety", 3);
    }
    if (!library && reqData.campaign_effort_id) {
      formData.append("variety", 2);
    }
    if (!library && reqData.location_id) {
      formData.append("variety", 8);
    }
    if (!library && reqData.organization_id) {
      formData.append("variety", 12);
    }
    if (!library && reqData.loot_item_id) {
      formData.append("variety", 11);
    }

    if (library && !uploadProps.library_variety) return;

    Object.keys(reqData).forEach((k) => formData.append(k, reqData[k]));

    axios
      .post(`/${library ? "library-" : ""}assets/`, formData)
      .then(({ data }) => {
        toast.success("Uploaded!");
        setShow(false);
        setOpen(false);
        setUploadProps({
          text: "",
          variety: null,
          color: "",
        });
        onSuccess(data.response[0]);

        setTimeout(() => {
          setShow(true);
        }, 1);
      })
      .catch((err) => {
        toast.error(err);
      });
  }

  if (library) {
    if (!show) return null;

    return (
      <React.Fragment>
        <Button
          size="xs"
          color="teal"
          variant="subtle"
          onClick={() => setOpen(true)}
          leftSection={<IconFileUpload />}
        >
          Upload Asset
        </Button>
        <Modal
          // title="New library asset"
          // size="xl"
          opened={open}
          onClose={() => setOpen(false)}
        >
          <Text fw={600} mb="sm">
            What kind of asset is this?
          </Text>
          <>
            {libraryAssetButtons.map((b, i) => (
              <Radio
                key={i}
                size="md"
                name="libvariety"
                checked={
                  uploadProps &&
                  uploadProps.library_variety === b.library_variety
                }
                label={b.text}
                onChange={() => setUploadProps(b)}
                mb={i !== libraryAssetButtons.length - 1 ? "xs" : ""}
              />
            ))}
          </>
          {/* <Group mt="xs" mb="xs">
            {libraryAssetButtons.map((b, i) => (
              <Button
                variant={
                  uploadProps &&
                  uploadProps.library_variety === b.library_variety
                    ? "filled"
                    : "outline"
                }
                onClick={() => setUploadProps(b)}
                color={b.color}
                size="xs"
                key={i}
              >
                {b.text}
              </Button>
            ))}
          </Group> */}
          <Divider mt="lg" mb="lg" />
          <FileButton
            accept={library ? "image/*,.pdf" : "image/*"}
            onChange={(e) => onUpload(e, uploadProps.variety)}
          >
            {(props) => (
              <Button
                {...props}
                variant="light"
                fullWidth
                color={uploadProps.color ? uploadProps.color : "green"}
                leftSection={<IconFileUpload size={20} />}
                disabled={!uploadProps.library_variety}
              >
                {`Upload ${uploadProps.text} Asset`}
              </Button>
            )}
          </FileButton>
        </Modal>
      </React.Fragment>
    );
  }

  return (
    <FileButton accept={"image/*"} onChange={(e) => onUpload(e)}>
      {(props) => (
        <Button
          {...props}
          variant="light"
          color="green"
          leftSection={<IconFileUpload />}
          size="xs"
        >
          Upload
        </Button>
      )}
    </FileButton>
  );
}
