import Compressor from "compressorjs";
import toast from "react-hot-toast";
import axios from "axios";

export const fieldTypeDefs = {
  image: "image",
  select: "select",
  radio: "radio",
  text: "text",
  textarea: "textarea",
  toggle: "toggle",
  checkbox: "checkbox",
};

export const fieldTypes = [
  { label: "Text", value: fieldTypeDefs.text, int: 3 },
  { label: "Textarea", value: fieldTypeDefs.textarea, int: 3 },
  { label: "Select", value: fieldTypeDefs.select, int: 4 },
  { label: "Checkbox", value: fieldTypeDefs.checkbox, int: 5 },
  { label: "Image Input", value: fieldTypeDefs.image, int: 6 },
  // { text: "Radio", value: fieldTypeDefs.radio },
  // { text: "Toggle", value: fieldTypeDefs.toggle },
];

export function getFieldType(variety) {
  const obj = fieldTypes.find((f) => f.int === variety);

  if (!obj) return;

  return obj.value;
}

export function populateEffortFields(fields = [], user = null, fieldValues) {
  const { phone, email } = fieldValues || {};

  const userResponses =
    user && user.responses
      ? Object.keys(user.responses)
          .map((m) => {
            return {
              label: m.toLowerCase(),
              option_label: user.responses[m]
                ? typeof user.responses[m] === "string"
                  ? user.responses[m].toLowerCase()
                  : user.responses[m]
                : null,
            };
          })
          .filter((f) => f.option_label)
      : [];

  return !fields
    ? []
    : !user
    ? [...fields]
        .map((m) => ({
          ...m,
          initialValue: m.name === "email" ? email : "",
        }))
        .filter((f) => (phone ? f.name !== "mobile_phone" : true))
        .filter((f) => (email ? f.name !== "email" : true))
    : [...fields]
        .filter((f) => {
          const userDemoKeys = !user.demographic_hash
            ? []
            : Object.keys(user.demographic_hash)
                // .filter((ff) => {
                //   const addressField = fields.find(
                //     (fff) => fff.name === "address_1"
                //   );
                //   return addressField ? ff !== "zip" : true;
                // })
                .map((m) => ({
                  key: m,
                  value: user.demographic_hash[m],
                }))
                .filter((f) => f.value)
                .map((m) => m.key);
          if (userDemoKeys.includes("address_1") && f.name === "address_2")
            return;
          if (userDemoKeys.includes(f.name) && f.db_property) {
            return;
          } else {
            return true;
          }
        })
        .filter((f) => (phone ? f.name !== "mobile_phone" : true))
        .map((m) => {
          const formatted = { ...m };
          const labelText = formatted.label.toLowerCase();
          const foundResponse = userResponses.find(
            (f) => f.label.toLowerCase() === labelText
          );
          if (!foundResponse) return formatted;
          if (formatted.options && formatted.options.length) {
            const foundOption = formatted.options
              .filter((f) => f.text)
              .find(
                (f) => f.label.toLowerCase() === foundResponse.option_label
              );
            if (!foundOption) return formatted;
            formatted.initialValue = foundOption.id;
          }
          return formatted;
        })
        .map((m) => {
          const formatted = { ...m };
          if (m.name === "email") {
            if (user) {
              formatted.initialValue = user.email;
            } else {
              formatted.initialValue = email;
            }
          }

          if (user) {
            if (m.name === "first_name")
              formatted.initialValue = user.first_name;
            if (m.name === "last_name") formatted.initialValue = user.last_name;
          }

          return formatted;
        });
}

function uploadImages(compressedImages) {
  return new Promise(function (resolve, reject) {
    const formData = new FormData();
    compressedImages.forEach((e) => {
      formData.append(e.id, e.file);
    });

    axios
      .post(`/assets/form-upload/`, formData)
      .then(({ data }) => {
        resolve(data.response);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}

export function compressImages(files) {
  return new Promise(function (resolve, reject) {
    let compressedImages = [];

    files.forEach((file, i) => {
      if (!file) return;
      new Compressor(file.file, {
        convertSize: 250000,
        success(result) {
          compressedImages.push({
            id: file.id,
            name: result.name,
            file: result,
          });
          if (i + 1 === files.length) {
            resolve(compressedImages);
          }
        },
      });
    });
  });
}

export function generateEffortFieldRequest(
  fields,
  formData,
  user,
  preview = false
) {
  return new Promise(function (resolve, reject) {
    const dbFields = fields.filter((f) => f.db_property);
    const otherFields = fields.filter(
      (f) => !f.db_property && f.type !== "image"
    );
    const formImageKeys = fields
      .filter((f) => f.type === "image")
      .map((m) => m.name);
    const demographics = {};
    dbFields.forEach(
      (f) =>
        (demographics[f.db_property] =
          formData[f.db_property] || (user && user[f.db_property])
            ? formData[f.db_property] || user[f.db_property]
            : "")
    );
    const other = {};
    otherFields.forEach((f) => (other[f.name] = formData[f.name] || ""));

    const req = {
      demographics,
      other,
    };

    if (formData.country) req.demographics.country = formData.country;

    if (preview) {
      return resolve(req);
    }

    if (formImageKeys.length > 0) {
      const formImageKeys = fields
        .filter((f) => f.type === "image")
        .map((m) => m.name);
      let images = [];
      formImageKeys.forEach((key) => {
        if (formData[key]) {
          images.push({
            id: key,
            file: formData[key],
          });
        }
      });
      compressImages(images)
        .then((compressedImages) => {
          uploadImages(compressedImages)
            .then((uploadedImages) => {
              resolve({
                ...req,
                other: {
                  ...req.other,
                  assets: uploadedImages,
                },
              });
            })
            .catch((err) => {
              toast.error(err);
            });
        })
        .catch((err) => {
          toast.error(err);
        });
    } else {
      resolve(req);
    }
  });
}
