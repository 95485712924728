import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Route, Routes, useLocation } from "react-router-dom";
import axios from "axios";
import {
  AppShell,
  Burger,
  Button,
  Divider,
  Flex,
  Group,
  Title,
  Modal,
  ScrollArea,
  Text,
  UnstyledButton,
  useMantineTheme,
} from "@mantine/core";
import { Toaster } from "react-hot-toast";
import { IconBomb } from "@tabler/icons-react";
import { ErrorBoundary } from "react-error-boundary";

import entityColor from "@util/entityColor";
import entityIcon from "@util/entityIcon";
import { Nameplate, Nav, NavGroup, SuspenseWrapper } from "@components/shared";
import { UserInteractionTable } from "@components/UserInteraction";

import classes from "./AdminContainer.module.css";

const MediaQuery = ({ children }) => <div>{children}</div>;

import {
  AdminIndex,
  AdminDetail,
  AppConfigIndex,
  ArtistDetail,
  ArtistIndex,
  ApiUseIndex,
  ApiUseDetail,
  AssetLibrary,
  AuctionDetail,
  BulkCommunicationDetail,
  BulkCommunicationIndex,
  CampaignIndex,
  CampaignDetail,
  ContestDetail,
  ContestIndex,
  CurationDetail,
  CurationIndex,
  Dashboard,
  EffortDetail,
  EffortIndex,
  EmailServerIndex,
  EmailValidationIndex,
  KeywordDetail,
  LocationDetail,
  LocationIndex,
  LootItemDetail,
  ManagerDetail,
  ManagerIndex,
  MessageIndex,
  OrganizationDetail,
  OrganizationIndex,
  PostmarkServerDetail,
  PlayDetail,
  PrizePoolDetail,
  ReportIndex,
  ReportRecipeDetail,
  ReportRecipeIndex,
  SongQueryDetail,
  SongDetail,
  SongIndex,
  TicketDetail,
  TicketIndex,
  UserDetail,
  UserIndex,
  UserJoinDetail,
  ServerSessionIndex,
  ServerSessionDetail,
  ApiRequestIndex,
  ApiRequestDetail,
} from "@pages/Admin";
import { LocationSongDetail } from "@pages/Manager";

import InteractionWizard from "@components/InteractionWizard";

export default function AdminContainer() {
  const loggedIn = useSelector((state) => state.admin) ? true : false;
  const adminInfo = useSelector((state) => state.admin);
  const avatarUrl = useSelector((state) => state.admin.avatar_url);
  const [opened, setOpened] = useState(false);
  const { pathname } = useLocation();

  const theme = useMantineTheme();

  useEffect(() => {
    setOpened(false);
  }, [pathname]);

  if (loggedIn) {
    return (
      <div>
        <AppShell
          padding="md"
          header={{ height: 65 }}
          navbar={{
            width: 250,
            breakpoint: "sm",
            collapsed: { mobile: !opened },
          }}
          styles={(theme) => ({
            // main: {
            //   backgroundColor:
            //     theme.colorScheme === "dark"
            //       ? theme.colors.dark[8]
            //       : theme.colors.gray[0],
            // },
          })}
        >
          <AppShell.Header
            p="xs"
            style={{
              background: `var(--mantine-color-dark-9)`,
            }}
          >
            <Group
              grow
              align="center"
              style={{
                height: "100%",
              }}
            >
              <Flex align="center" gap="md">
                <div className={classes.burger}>
                  <Burger
                    opened={opened}
                    onClick={() => setOpened(!opened)}
                    size="lg"
                    color={theme.colors.dark[1]}
                    mr="xl"
                  />
                </div>
                {/* <MediaQuery smallerThan="sm" styles={{ display: "none" }}>
                  <img src={logo} alt="Mixer" width={80} />
                </MediaQuery> */}
                <div className={classes.logo}>
                  <img
                    src={
                      "https://mixer-public-assets.s3.amazonaws.com/logo-white.png"
                    }
                    alt="Mixer"
                    width={80}
                  />
                </div>
                <InteractionWizard />
              </Flex>
              <div className={classes.right}>
                <Group position="right" align="flexEnd" grow>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Nameplate
                      avatarUrl={avatarUrl}
                      title={adminInfo.full_name}
                      subtitle="Admin"
                    />
                  </div>
                </Group>
              </div>
            </Group>
          </AppShell.Header>
          <AppShell.Navbar pt="md" hidden={!opened}>
            {/* <Navbar.Section style={{ background: "#000" }} p="lg">
                <Image
                  src={logo}
                  alt="Mixer"
                  width={120}
                  style={{ margin: "0 auto" }}
                />
              </Navbar.Section> */}
            <AppShell.Section grow component={ScrollArea}>
              <NavGroup
                title="Music"
                links={[
                  {
                    text: "Songs",
                    to: "songs",
                    icon: entityIcon.song(),
                    // color: entityColor.campaign,
                  },
                  {
                    text: "Artists",
                    to: "artists",
                    icon: entityIcon.artist(),
                    // color: entityColor.campaign,
                  },
                  {
                    text: "Curation",
                    to: "curation",
                    icon: entityIcon.curation(),
                    color: entityColor.email,
                  },
                ].map((m, i) => ({
                  ...m,
                  key: i,
                  to: `/${m.to}`,
                }))}
              />
              <NavGroup
                title="People"
                links={[
                  {
                    text: "Admins",
                    to: "admins",
                    icon: entityIcon.admin(),
                    color: entityColor.admin,
                  },
                  {
                    text: "Managers",
                    to: "managers",
                    icon: entityIcon.manager(),
                    color: entityColor.manager,
                  },
                  {
                    text: "Users",
                    to: "users",
                    icon: entityIcon.user(),
                    color: entityColor.user,
                  },
                ].map((m, i) => ({
                  ...m,
                  key: i,
                  to: `/${m.to}`,
                }))}
              />
              <NavGroup
                title="Messaging"
                links={[
                  {
                    text: "Validation",
                    to: "email-validation",
                    icon: entityIcon.email(),
                    color: entityColor.email,
                    value: true,
                  },
                  {
                    text: "Entity Servers",
                    to: "entity-email-servers",
                    icon: entityIcon.email(),
                    color: entityColor.email,
                    value: true,
                  },
                  {
                    text: "Postmark",
                    to: `postmark/${adminInfo.postmark_server_id}`,
                    icon: entityIcon.email(),
                    color: entityColor.email,
                    value: adminInfo.postmark_server_id,
                  },
                  {
                    text: "Messages",
                    to: "messages",
                    icon: entityIcon.message(),
                    color: entityColor.message,
                    value: true,
                  },
                ]
                  .filter((f) => f.value)
                  .map((m, i) => ({
                    ...m,
                    key: i,
                    to: `/${m.to}`,
                  }))}
              />
              <NavGroup
                title="Server Audits"
                links={[
                  {
                    text: "Server Sessions",
                    to: "server-sessions",
                    icon: entityIcon.session(),
                    color: entityColor.session,
                  },
                  {
                    text: "Api Requests",
                    to: "incoming-api-requests",
                    icon: entityIcon.request(),
                    color: entityColor.request,
                  },
                ].map((m, i) => ({
                  ...m,
                  key: i,
                  to: `/${m.to}`,
                }))}
              />
              <Nav
                links={[
                  ...navLinks,
                  {
                    text: "App Config",
                    to: `app-config`,
                    icon: entityIcon.request(),
                    color: entityColor.request,
                  },
                ]
                  .filter((f) =>
                    !adminInfo.app_configuration
                      ? !["App Config"].includes(f.text)
                      : true
                  )
                  .map((m) => ({
                    ...m,
                    to: `/${m.to}`,
                  }))}
              />
              {["dev", "qa", "demo"].includes(import.meta.env.VITE_ENV) && (
                <ClearUserData />
              )}
            </AppShell.Section>
            <div className={classes.nameplate}>
              <Group mb="sm" grow>
                <div>
                  <Nameplate title={adminInfo.full_name} subtitle="Admin" />
                </div>
              </Group>
            </div>
          </AppShell.Navbar>
          <AppShell.Main
            style={(theme) => ({
              background: theme.colors.gray[0],
            })}
          >
            <div style={{ maxWidth: "1400px", margin: "0 auto" }}>
              <ErrorBoundary
                fallback={
                  <div>
                    <Title>That's not good!</Title>
                    <Text>Something went wrong and we're going to fix it.</Text>
                  </div>
                }
              >
                <SuspenseWrapper>
                  <Routes>
                    <Route index element={<Dashboard />} />
                    <Route path="/admins" element={<AdminIndex />} />
                    <Route path="/admins/:id" element={<AdminDetail />} />
                    <Route path="/artists" element={<ArtistIndex />} />
                    <Route path="/artists/:id/*" element={<ArtistDetail />} />
                    <Route path="/auctions/:id/*" element={<AuctionDetail />} />
                    <Route path="/campaigns" element={<CampaignIndex />} />
                    <Route
                      path="/campaigns/:id/*"
                      element={<CampaignDetail />}
                    />
                    <Route path="/contests" element={<ContestIndex />} />
                    <Route path="/contests/:id/*" element={<ContestDetail />} />
                    <Route path="/curation" element={<CurationIndex />} />
                    <Route
                      path="/curation-artist/:id/*"
                      element={<CurationDetail isArtist />}
                    />
                    <Route
                      path="/curation-song/:id/*"
                      element={<CurationDetail isSong />}
                    />
                    <Route path="/efforts" element={<EffortIndex />} />
                    <Route path="/efforts/:id/*" element={<EffortDetail />} />
                    <Route
                      path="/loot-items/:id/*"
                      element={<LootItemDetail />}
                    />
                    <Route
                      path="/interactions"
                      element={<UserInteractionTable />}
                    />
                    <Route
                      path="/organizations"
                      element={<OrganizationIndex />}
                    />
                    <Route
                      path="/organizations/:id/*"
                      element={<OrganizationDetail />}
                    />
                    <Route path="/keywords/:id/*" element={<KeywordDetail />} />
                    <Route path="/locations" element={<LocationIndex />} />
                    <Route
                      path="/locations/:id/*"
                      element={<LocationDetail isAdmin />}
                    />
                    <Route path="/managers" element={<ManagerIndex />} />
                    <Route
                      path="/managers/:id/*"
                      element={<ManagerDetail isAdmin />}
                    />
                    <Route path="/messages" element={<MessageIndex />} />
                    <Route path="/plays/:id/*" element={<PlayDetail />} />
                    <Route
                      path="/prize-pools/:id/*"
                      element={<PrizePoolDetail />}
                    />
                    <Route
                      path="/profile"
                      element={<AdminDetail isProfile />}
                    />
                    <Route path="/reports" element={<ReportIndex />} />
                    <Route
                      path="/location-songs/:id/*"
                      element={<LocationSongDetail isAdmin />}
                    />
                    <Route path="/songs" element={<SongIndex />} />
                    <Route
                      path="/songs/:id/*"
                      element={<SongDetail isAdmin />}
                    />
                    <Route
                      path="/song-categories/:id/*"
                      element={<SongQueryDetail isAdmin />}
                    />
                    <Route path="/users" element={<UserIndex />} />
                    <Route path="/users/:id/*" element={<UserDetail />} />
                    <Route
                      path="/user-locations/:id/*"
                      element={<UserJoinDetail location />}
                    />
                    <Route
                      path="/user-organizations/:id/*"
                      element={<UserJoinDetail organization />}
                    />
                    <Route
                      path="/library"
                      element={<AssetLibrary isIndex library />}
                    />
                    <Route
                      path="/email-validation"
                      element={<EmailValidationIndex />}
                    />
                    <Route
                      path="/entity-email-servers"
                      element={<EmailServerIndex />}
                    />
                    <Route path="/trouble-tickets" element={<TicketIndex />} />
                    <Route
                      path="/trouble-tickets/:id/*"
                      element={<TicketDetail />}
                    />
                    <Route path="/third-party-uses" element={<ApiUseIndex />} />
                    <Route
                      path="/third-party-uses/:id/*"
                      element={<ApiUseDetail />}
                    />
                    <Route
                      path="/postmark/:id/*"
                      element={<PostmarkServerDetail />}
                    />
                    <Route
                      path="/server-sessions"
                      element={<ServerSessionIndex />}
                    />
                    <Route
                      path="/server-sessions/:id/*"
                      element={<ServerSessionDetail />}
                    />
                    <Route
                      path="/incoming-api-requests"
                      element={<ApiRequestIndex />}
                    />
                    <Route
                      path="/incoming-api-requests/:id/*"
                      element={<ApiRequestDetail />}
                    />
                    <Route
                      path="/bulk-communication"
                      element={<BulkCommunicationIndex />}
                    />
                    <Route
                      path="/bulk-communication/:id/*"
                      element={<BulkCommunicationDetail />}
                    />
                    <Route
                      path="/report-recipes"
                      element={<ReportRecipeIndex />}
                    />
                    <Route
                      path="/all-report-recipes"
                      element={<ReportRecipeIndex editing />}
                    />
                    <Route
                      path="/report-recipes/:id/*"
                      element={<ReportRecipeDetail />}
                    />
                    {adminInfo.app_configuration && (
                      <Route path="/app-config" element={<AppConfigIndex />} />
                    )}
                  </Routes>
                </SuspenseWrapper>
              </ErrorBoundary>
            </div>
          </AppShell.Main>
        </AppShell>
        <Toaster />
      </div>
    );
  }
}

const navLinks = [
  {
    text: "Dashboard",
    to: "",
    icon: entityIcon.dashboard(),
    // color: entityColor.campaign,
  },
  {
    text: "Campaigns",
    to: "campaigns",
    icon: entityIcon.campaign(),
    color: entityColor.campaign,
  },
  {
    text: "Efforts",
    to: "efforts",
    icon: entityIcon.effort(),
    color: entityColor.effort,
  },
  {
    text: "Organizations",
    to: "organizations",
    icon: entityIcon.organization(),
    color: entityColor.organization,
  },
  {
    text: "Locations",
    to: "locations",
    icon: entityIcon.location(),
    color: entityColor.location,
  },
  {
    text: "Reports",
    to: "reports",
    icon: entityIcon.report(),
    color: entityColor.report,
  },
  {
    text: "Contests",
    to: "contests",
    icon: entityIcon.contest(),
    color: entityColor.contest,
  },
  {
    text: "Interactions",
    to: "interactions",
    icon: entityIcon.report(),
    color: entityColor.report,
  },
  {
    text: "Asset Library",
    to: "library",
    icon: entityIcon.library(),
    color: entityColor.library,
  },
  // {
  //   text: "Validation",
  //   to: "email-validation",
  //   icon: entityIcon.email(),
  //   color: entityColor.email,
  // },
  {
    text: "Trouble Tickets",
    to: "trouble-tickets",
    icon: entityIcon.ticket(),
    color: entityColor.ticket,
  },
  // {
  //   text: "Email Servers",
  //   to: "email-servers",
  //   icon: entityIcon.email(),
  //   color: entityColor.email,
  // },
  {
    text: "3rd Party Api Uses",
    to: "third-party-uses",
    icon: entityIcon.api(),
    color: entityColor.api,
  },
  {
    text: "Report Recipes",
    to: "report-recipes",
    icon: entityIcon.report(),
    color: entityColor.report,
  },
  {
    text: "Bulk Communicator",
    to: "bulk-communication",
    icon: entityIcon.send(),
    color: entityColor.send,
  },
];

function ClearUserData() {
  const [isOpen, setOpen] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [loading, setLoading] = useState(false);

  function onClose() {
    if (loading) return;
    setOpen(false);
    setShowConfirm(false);
  }

  function onConfirmClick() {
    setLoading(true);

    axios
      .post(`/testing/reset-actions/`, {})
      .then(() => {
        window.location.reload();
      })
      .catch((err) => {
        setLoading(false);
        alert(err);
      });
  }

  return (
    <React.Fragment>
      <Divider mt="md" mb="md" />
      <UnstyledButton
        style={{
          display: "block",
          width: "100%",
          padding: "var(--mantine-spacing-xs)",
          borderRadius: "var(--mantine-radius-sm)",
          // "&:hover": {
          //   backgroundColor:
          //     theme.colorScheme === "dark"
          //       ? theme.colors.dark[6]
          //       : theme.colors.gray[0],
          // },
        }}
        onClick={() => setOpen(true)}
      >
        <Group>
          <IconBomb size={12} />
          <Text size="xs">Don't Click</Text>
        </Group>
      </UnstyledButton>
      <Modal opened={isOpen} onClose={onClose}>
        <Group justify="center" mb="sm">
          <IconBomb size={60} />
        </Group>
        {!showConfirm ? (
          <React.Fragment>
            <Text align="center" fw={600}>
              You are about to clear all user data. Are you sure you want to
              proceed?
            </Text>
            <Button fullWidth mt="lg" onClick={onClose} color="green">
              Nope! Get me out of here
            </Button>
            <Group justify="center">
              <Button
                mt="sm"
                variant="subtle"
                color="gray"
                onClick={() => setShowConfirm(true)}
              >
                I'm sure
              </Button>
            </Group>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Text align="center" fw={600} mb="sm">
              Current ENV: {import.meta.env.VITE_ENV}
            </Text>
            <Text align="center" fw={600}>
              This is really going to f*** s*** up and delete all users and user
              interactions for {import.meta.env.VITE_ENV}. Are you sure you want
              to proceed?
            </Text>
            <Button fullWidth mt="lg" onClick={onClose} color="green">
              Nope! Get me out of here
            </Button>
            <Group justify="center">
              <Button
                mt="sm"
                variant="subtle"
                color="gray"
                loading={loading}
                onClick={onConfirmClick}
              >
                I'm sure
              </Button>
            </Group>
          </React.Fragment>
        )}
      </Modal>
    </React.Fragment>
  );
}
