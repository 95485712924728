import React, { useState } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import * as Yup from "yup";
import { BasicForm } from "@components/shared";

import { initialPrizeSettings } from "./helpers";

export default function PrizeForm({
  id = null,
  name = "",
  description = "",
  onSuccess = () => {},
  contestId,
}) {
  const [loading, setLoading] = useState(false);

  function onSubmit(formData) {
    const formatted = {
      ...formData,
      contest_id: contestId,
    };
    setLoading(true);

    if (id) return onUpdate(formatted);

    return onCreate(formatted);
  }

  function onCreate(formData) {
    axios
      .post(`/prizes/`, { ...formData, settings: initialPrizeSettings })
      .then(() => setLoading(false))
      .then(() => toast.success("Created!"))
      .then(() => onSuccess())
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  function onUpdate(formData) {
    const req = {
      ...formData,
      prize_id: id,
    };

    axios
      .put(`/prizes/${id}/`, req)
      .then(() => setLoading(false))
      .then(() => toast.success("Updated!"))
      .then(() => onSuccess())
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  const fields = [
    {
      name: "name",
      label: "Name",
      initialValue: name,
      required: true,
      schema: () => Yup.string().required("Required"),
    },
    {
      name: "description",
      label: "Description",
      textarea: true,
      initialValue: description,
      required: true,
      schema: () => Yup.string().required("Required"),
    },
  ];

  return <BasicForm loading={loading} onSubmit={onSubmit} fields={fields} />;
}
