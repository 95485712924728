import React, { useState, useEffect } from "react";
import { Text, TextInput, Group, Button, Card, Title } from "@mantine/core";
import axios from "axios";
import toast from "react-hot-toast";

import { UserContactSelect } from "@components/User";

export default function UserInteractionEdit({
  isManager = false,
  joinId,
  rawData,
  fetchData,
}) {
  const [values, setValues] = useState({
    first_name: "",
    last_name: "",
  });
  const [saving, setSaving] = useState(false);

  const joinKey = rawData.is_location
    ? "user_location_id"
    : "user_organization_id";
  const userId = rawData.user_id;

  useEffect(() => {
    setValues({
      first_name: rawData.first_name,
      last_name: rawData.last_name,
    });
  }, [JSON.stringify(rawData)]);

  function onNameSave() {
    const req = {
      ...values,
      [joinKey]: joinId,
    };

    setSaving(true);

    axios
      .post(`/manage-user-join/`, req)
      .then(() => {
        toast.success("Updated!");
        setSaving(false);
        fetchData();
      })
      .catch((err) => {
        setSaving(false);
        toast.error(err);
      });
  }

  return (
    <div>
      <Title mb="xs" order={4}>
        Name
      </Title>
      <Card>
        <Group align="flex-end">
          <TextInput
            label="First Name"
            value={values.first_name}
            onChange={(e) =>
              setValues({
                ...values,
                first_name: e.target.value,
              })
            }
          />
          <TextInput
            label="Last Name"
            value={values.last_name}
            onChange={(e) =>
              setValues({
                ...values,
                last_name: e.target.value,
              })
            }
          />
          <Button loading={saving} onClick={onNameSave}>
            Save
          </Button>
        </Group>
      </Card>
      <Title mt="xl" mb="xs" order={4}>
        Phone
      </Title>
      <Card>
        <PhoneEdit
          editable={isManager ? false : true}
          userId={userId}
          phone={rawData.phone}
          fetchData={fetchData}
          reqData={{
            [joinKey]: joinId,
          }}
        />
      </Card>
      <Title mt="xl" mb="xs" order={4}>
        Email
      </Title>
      <Card>
        <EmailEdit
          editable={isManager ? false : true}
          userId={userId}
          email={rawData.user_email}
          fetchData={fetchData}
          reqData={{
            [joinKey]: joinId,
          }}
        />
      </Card>
    </div>
  );
}

const PhoneEdit = ({ editable = true, userId, phone, fetchData, reqData }) => {
  const [isOpen, setOpen] = useState(false);
  const [selected, setSelected] = useState(null);
  const [loading, setLoading] = useState(false);

  const currentPhoneId = phone ? phone.id : null;

  useEffect(() => {
    if (!isOpen) {
      setLoading(false);
    }
  }, [isOpen]);

  function onContactMethodCreate(newMethodId) {
    onSubmit(newMethodId);
  }

  function onSubmit(newMethodId = null) {
    setLoading(true);

    const phoneId = newMethodId ? newMethodId : selected;

    const req = {
      ...reqData,
      phone_id: phoneId,
    };

    axios
      .post(`/manage-user-join/`, req)
      .then(() => {
        toast.success("Updated!");
        setLoading(false);
        setOpen(false);
        fetchData();
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  function onTransferSubmit() {
    const req = {
      ...reqData,
      user_id: userId,
      phone_id: selected,
    };

    axios
      .post(`/user-contact-corrections/`, req)
      .then(() => {
        setLoading(true);
        toast.success("Transferred!");
        setOpen(false);
        fetchData();
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  // if (!phone) return <Text>Phone missing</Text>;

  return (
    <React.Fragment>
      <Group>
        <Text>{phone ? phone.number_formatted : "Phone missing"}</Text>
        <UserContactSelect
          activeId={currentPhoneId}
          editable={editable}
          isOpen={isOpen}
          isPhone
          loading={loading}
          onChange={(id) => setSelected(id)}
          onContactMethodCreate={onContactMethodCreate}
          onSubmit={onSubmit}
          onTransferSubmit={onTransferSubmit}
          phoneData={phone}
          selected={selected}
          setOpen={(o) => setOpen(o)}
          userId={userId}
        />
      </Group>
    </React.Fragment>
  );
};

const EmailEdit = ({ editable = true, userId, email, fetchData, reqData }) => {
  const [isOpen, setOpen] = useState(false);
  const [selected, setSelected] = useState(null);
  const [loading, setLoading] = useState(false);

  const currentEmailId = email ? email.id : null;

  useEffect(() => {
    if (!isOpen) {
      setLoading(false);
    }
  }, [isOpen]);

  function onContactMethodCreate(newMethodId) {
    onSubmit(newMethodId);
  }

  function onSubmit(newMethodId = null) {
    setLoading(true);

    const emailId = newMethodId ? newMethodId : selected;

    const req = {
      ...reqData,
      user_email_id: emailId,
    };

    axios
      .post(`/manage-user-join/`, req)
      .then(() => {
        toast.success("Updated!");
        setLoading(false);
        setOpen(false);
        fetchData();
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  function onTransferSubmit() {
    const req = {
      ...reqData,
      user_id: userId,
      user_email_id: selected,
    };

    axios
      .post(`/user-contact-corrections/`, req)
      .then(() => {
        setLoading(true);
        toast.success("Transferred!");
        setOpen(false);
        fetchData();
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  return (
    <React.Fragment>
      <Group>
        <Text>{email ? email.address : "Email missing"}</Text>
        <UserContactSelect
          activeId={currentEmailId}
          editable={editable}
          emailData={email}
          isEmail
          isOpen={isOpen}
          loading={loading}
          onChange={(id) => setSelected(id)}
          onContactMethodCreate={onContactMethodCreate}
          onSubmit={onSubmit}
          onTransferSubmit={onTransferSubmit}
          selected={selected}
          setOpen={(o) => setOpen(o)}
          userId={userId}
        />
      </Group>
    </React.Fragment>
  );
};
