const usStates = [
  {
    label: "Alabama",
    value: "AL",
    key: "AL",
  },
  {
    label: "Alaska",
    value: "AK",
    key: "AK",
  },
  {
    label: "Arizona",
    value: "AZ",
    key: "AZ",
  },
  {
    label: "Arkansas",
    value: "AR",
    key: "AR",
  },
  {
    label: "California",
    value: "CA",
    key: "CA",
  },
  {
    label: "Colorado",
    value: "CO",
    key: "CO",
  },
  {
    label: "Connecticut",
    value: "CT",
    key: "CT",
  },
  {
    label: "Delaware",
    value: "DE",
    key: "DE",
  },
  {
    label: "Florida",
    value: "FL",
    key: "FL",
  },
  {
    label: "Georgia",
    value: "GA",
    key: "GA",
  },
  {
    label: "Hawaii",
    value: "HI",
    key: "HI",
  },
  {
    label: "Idaho",
    value: "ID",
    key: "ID",
  },
  {
    label: "Illinois",
    value: "IL",
    key: "IL",
  },
  {
    label: "Indiana",
    value: "IN",
    key: "IN",
  },
  {
    label: "Iowa",
    value: "IA",
    key: "IA",
  },
  {
    label: "Kansas",
    value: "KS",
    key: "KS",
  },
  {
    label: "Kentucky",
    value: "KY",
    key: "KY",
  },
  {
    label: "Louisiana",
    value: "LA",
    key: "LA",
  },
  {
    label: "Maine",
    value: "ME",
    key: "ME",
  },
  {
    label: "Maryland",
    value: "MD",
    key: "MD",
  },
  {
    label: "Massachusetts",
    value: "MA",
    key: "MA",
  },
  {
    label: "Michigan",
    value: "MI",
    key: "MI",
  },
  {
    label: "Minnesota",
    value: "MN",
    key: "MN",
  },
  {
    label: "Mississippi",
    value: "MS",
    key: "MS",
  },
  {
    label: "Missouri",
    value: "MO",
    key: "MO",
  },
  {
    label: "Montana",
    value: "MT",
    key: "MT",
  },
  {
    label: "Nebraska",
    value: "NE",
    key: "NE",
  },
  {
    label: "Nevada",
    value: "NV",
    key: "NV",
  },
  {
    label: "New Hampshire",
    value: "NH",
    key: "NH",
  },
  {
    label: "New Jersey",
    value: "NJ",
    key: "NJ",
  },
  {
    label: "New Mexico",
    value: "NM",
    key: "NM",
  },
  {
    label: "New York",
    value: "NY",
    key: "NY",
  },
  {
    label: "North Carolina",
    value: "NC",
    key: "NC",
  },
  {
    label: "North Dakota",
    value: "ND",
    key: "ND",
  },
  {
    label: "Ohio",
    value: "OH",
    key: "OH",
  },
  {
    label: "Oklahoma",
    value: "OK",
    key: "OK",
  },
  {
    label: "Oregon",
    value: "OR",
    key: "OR",
  },
  {
    label: "Pennsylvania",
    value: "PA",
    key: "PA",
  },
  {
    label: "Rhode Island",
    value: "RI",
    key: "RI",
  },
  {
    label: "South Carolina",
    value: "SC",
    key: "SC",
  },
  {
    label: "South Dakota",
    value: "SD",
    key: "SD",
  },
  {
    label: "Tennessee",
    value: "TN",
    key: "TN",
  },
  {
    label: "Texas",
    value: "TX",
    key: "TX",
  },
  {
    label: "Utah",
    value: "UT",
    key: "UT",
  },
  {
    label: "Vermont",
    value: "VT",
    key: "VT",
  },
  {
    label: "Virginia",
    value: "VA",
    key: "VA",
  },
  {
    label: "Washington",
    value: "WA",
    key: "WA",
  },
  {
    label: "West Virginia",
    value: "WV",
    key: "WV",
  },
  {
    label: "Wisconsin",
    value: "WI",
    key: "WI",
  },
  {
    label: "Wyoming",
    value: "WY",
    key: "WY",
  },
  {
    label: "Alberta",
    value: "AB",
  },
  {
    label: "British Columbia",
    value: "BC",
  },
  {
    label: "Manitoba",
    value: "MB",
  },
  {
    label: "New Brunswick",
    value: "NB",
  },
  {
    label: "Newfoundland and Labrador",
    value: "NL",
  },
  {
    label: "Nova Scotia",
    value: "NS",
  },
  {
    label: "Ontario",
    value: "ON",
  },
  {
    label: "Prince Edward Island",
    value: "PE",
  },
  {
    label: "Quebec",
    value: "QC",
  },
  {
    label: "Saskatchewan",
    value: "SK",
  },
].map((m) => ({ ...m, key: m.value }));

export default usStates;
