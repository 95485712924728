import React, { useContext, useState, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";
import { Button, Grid } from "@mantine/core";

import { getUuid } from "@util/getUuid";
import { fieldTypeDefs, getFieldType } from "./helpers";
import { Context } from "./";

const addressKeys = ["address_1", "address_2", "city", "state_province"];

export default function FieldLibrary({ onClick, onAddressSelect }) {
  const [libFields, setLibFields] = useState([]);
  const { fields } = useContext(Context);
  const dbFields = fields
    .filter((f) => f.db_property)
    .map((m) => m.db_property);

  useEffect(() => {
    fetchFields();
  }, []);

  function fetchFields() {
    axios
      .post(`/retrieve-user-engagement-items/`)
      .then(({ data }) => {
        setLibFields(
          data.response.map((m) => ({
            text: m.interrogative,
            uuid: getUuid(),
            field_info: {
              uuid: getUuid(),
              name: m.db_property,
              required: true,
              label: m.interrogative,
              addressLookup: m.db_property === "address_1",
              db_property: m.db_property,
              options:
                m.select_items && m.select_items.length
                  ? m.select_items.map((m) => ({
                      key: m.id,
                      text: m.label,
                      value:
                        m.db_property === "state_province" ? m.label : m.id,
                    }))
                  : [],
              type:
                m.select_items && m.select_items.length
                  ? fieldTypeDefs.select
                  : fieldTypeDefs.text,
            },
          }))
        );
      })
      .catch((err) => {
        setLibFields([]);
      });
  }

  function onAddressClick() {
    const addressFields = libFields
      .filter((f) => addressKeys.includes(f.field_info.db_property))
      .map((m) => ({
        ...m.field_info,
        required:
          m.field_info.db_property === "address_2"
            ? false
            : m.field_info.required,
      }));

    onAddressSelect(addressFields);
  }

  const filteredFields = libFields.filter(
    (f) => ![...addressKeys, "country"].includes(f.field_info.db_property)
  );

  const fieldNames = fields.map((f) => f.name);

  return (
    <Grid>
      {filteredFields.map((f) => (
        <Grid.Col key={f.uuid} span={{ base: 12, md: 4 }}>
          <Button
            size="xs"
            fullWidth
            variant="light"
            disabled={dbFields.includes(f.field_info.db_property)}
            onClick={() => onClick(f.field_info)}
          >
            {f.text}
          </Button>
        </Grid.Col>
      ))}
      <Grid.Col span={{ base: 12, md: 4 }}>
        <Button
          size="xs"
          fullWidth
          variant="light"
          disabled={
            addressKeys.some((r) => fieldNames.includes(r)) ? true : false
          }
          onClick={onAddressClick}
        >
          Address
        </Button>
      </Grid.Col>
    </Grid>
  );
}

const StyledSelectButton = styled.button`
  cursor: pointer;
  background: #eee;
  color: inherit;
  border: 1px solid #cdcdcd;
  padding: 5px;
  border-radius: 4px;
  font-family: inherit;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.25;
  }
`;

const libraryFields = [
  {
    field_info: {
      name: "first_name",
      label: "First Name",
      type: fieldTypeDefs.text,
    },
  },
  {
    field_info: {
      name: "last_name",
      label: "Last Name",
      type: fieldTypeDefs.text,
    },
  },
  {
    field_info: {
      name: "gender",
      label: "Gender",
      type: fieldTypeDefs.select,
      options: [
        { text: "Male", value: "male" },
        { text: "Female", value: "female" },
        { text: "Other", value: "other" },
      ],
    },
  },
  {
    field_info: {
      name: "email",
      label: "Email",
      type: fieldTypeDefs.text,
    },
  },
]
  .map((m) => ({
    ...m,
    uuid: getUuid(),
    text: m.field_info.label,
  }))
  .map((m) => ({
    ...m,
    field_info: {
      ...m.field_info,
      required: true,
      uuid: m.uuid,
      options: m.field_info.options
        ? m.field_info.options.map((o) => ({ ...o, uuid: getUuid() }))
        : [],
    },
  }));
