import React, { useState } from "react";
import axios from "axios";
import { TextInput, Button } from "@mantine/core";
import toast from "react-hot-toast";
import styled from "styled-components";

export default function ForgotPassword({ isAdmin = false, isManager, isUser }) {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  function onSubmit(e) {
    e.preventDefault();

    const req = {
      email,
    };

    if (isAdmin) req.admin = true;
    if (isManager) req.manager = true;
    if (isUser) req.user = true;

    axios
      .post(`/forgot-password/`, req)
      .then(() => {
        setLoading(false);
        toast.success(
          `Check your ${email} inbox for password reset instructions`
        );
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  return (
    <StyledForm>
      <TextInput
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        placeholder="email"
        size="md"
      />
      <Button type="submit" disabled={!email} onClick={onSubmit}>
        Submit
      </Button>
    </StyledForm>
  );
}

const StyledForm = styled.form`
  margin: 0 auto;
  width: 300px;

  .mantine-Checkbox-labelWrapper {
    label {
      color: #fff;
    }
  }
`;
