import React, { useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import * as Yup from "yup";
import { BasicForm } from "@components/shared";

import timezones from "@lib/timezones";
import { todayStart, dayStart, dayEnd } from "@util/dates";

export default function EffortForm({
  allowManagerEdit = true,
  apiContext = "",
  apiAppEnum = "",
  apiProgram = "",
  campaignId,
  endDate = "",
  endTime = dayEnd,
  id = null,
  internalName = "",
  micrositeGallery = true,
  onSuccess = () => {},
  pixelCode = "",
  sendFromEmail = "",
  sendRegistrationEmail = false,
  shareData = true,
  showShareData = true,
  startDate = todayStart,
  startTime = dayStart,
  thirdPartyApiEnabled = false,
  thirdPartyCampaignCode = "",
  title = "",
  timezone = null,
  variety,
  wizardSetup = false,
  wizardSettings = {},
}) {
  const [loading, setLoading] = useState(false);
  const managerInfo = useSelector((state) => state.manager);
  const isManager = managerInfo ? true : false;
  const navigate = useNavigate();

  function onSubmit(formData) {
    setLoading(true);

    if (wizardSetup) {
      return onUpdate(formData);
    }

    const formatted = {
      ...formData,
    };

    if (id) return onUpdate(formatted);

    return onCreate(formatted);
  }

  function onCreate(formData) {
    const req = {
      ...formData,
      campaign_id: campaignId,
    };

    if (managerInfo) {
      if (managerInfo.location_id) req.location_id = managerInfo.location_id;
      if (managerInfo.organization_id)
        req.organization_id = managerInfo.organization_id;
    }

    axios
      .post(`/efforts/`, req)
      .then(({ data }) => {
        setLoading(false);
        toast.success("Created!");
        navigate(`/efforts/${data.response[0].id}`);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  function onUpdate(formData) {
    const req = {
      ...formData,
      effort_id: id,
    };

    axios
      .put(`/efforts/${id}/`, req)
      .then(() => setLoading(false))
      .then(() => toast.success("Updated!"))
      .then(() => onSuccess())
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  const fields = [
    {
      name: "title",
      label: "Title",
      initialValue: title,
      required: true,
      schema: () => Yup.string().required("Required"),
    },
    {
      name: "internal_name",
      label: "Internal Name",
      initialValue: internalName,
      required: true,
      schema: () => Yup.string().required("Required"),
    },
    {
      name: "start_date",
      initialValue: startDate,
      required: true,
      schema: () => Yup.string("Required").nullable().required("Required"),
      label: "Start Date",
      datepicker: {
        min: !id ? todayStart : "",
      },
    },
    {
      name: "start_time",
      initialValue: startTime,
      required: true,
      schema: () => Yup.string("Required").nullable().required("Required"),
      label: `Start Time${!id ? " (defaults to start of day)" : ""}`,
      timepicker: {},
    },
    {
      name: "end_date",
      initialValue: endDate,
      required: true,
      schema: () => Yup.string("Required").nullable().required("Required"),
      label: "End Date",
      datepicker: {
        min: !id ? todayStart : "",
      },
    },
    {
      name: "end_time",
      initialValue: endTime,
      required: true,
      schema: () => Yup.string("Required").nullable().required("Required"),
      label: `End Time${!id ? " (defaults to end of day)" : ""}`,
      timepicker: {},
    },
    {
      name: "timezone",
      initialValue: timezone,
      required: true,
      select: true,
      schema: () => Yup.string("Required").nullable().required("Required"),
      label: "Timezone",
      options: timezones,
      disabled: id ? true : false,
    },
    {
      name: "send_from_email",
      label: "Send From Email",
      initialValue: sendFromEmail,
      required: true,
      schema: () =>
        Yup.string().email("Enter a valid email address").required("Required"),
    },
    {
      name: "pixel_code",
      label: "Meta Pixel ID",
      initialValue: pixelCode,
      required: false,
      schema: () => Yup.string(),
    },
    {
      name: "third_party_campaign_code",
      label: "Third Party Campaign Code",
      initialValue: thirdPartyCampaignCode,
      required: false,
      schema: () => Yup.string(),
    },
    {
      name: "api_context",
      label: "API Context",
      initialValue: apiContext,
      required: false,
      schema: () => Yup.string(),
    },
    {
      name: "api_program",
      label: "API Program",
      initialValue: apiProgram,
      required: false,
      schema: () => Yup.string(),
    },
    {
      name: "api_app_enum",
      label: "API App Enum",
      initialValue: apiAppEnum,
      required: false,
      schema: () => null,
      select: true,
      options: [{ label: "Lead API", value: "5" }],
    },
    {
      name: "third_party_api_enabled",
      label: "Third Party API Enabled",
      initialValue: thirdPartyApiEnabled,
      required: false,
      schema: () => null,
      toggle: true,
    },
    {
      name: "allow_manager_edit",
      label: "Allow manager to edit",
      initialValue: allowManagerEdit,
      required: false,
      schema: () => null,
      toggle: true,
    },
    {
      name: "send_registration_email",
      label: "Send registration confirmation email",
      initialValue: sendRegistrationEmail,
      required: false,
      schema: () => null,
      toggle: true,
    },
    {
      name: "share_data",
      label: "Share data with locations",
      initialValue: shareData,
      required: false,
      schema: () => null,
      toggle: true,
    },
    // {
    //   name: "variety",
    //   label: "Variety",
    //   initialValue: 1,
    //   required: true,
    //   select: true,
    //   schema: () => Yup.string().nullable().required("Required"),
    //   options: [
    //     { label: "Sweepstakes", value: 1 },
    //     { label: "Survey", value: 2 },
    //   ],
    // },
    // {
    //   name: "award_type",
    //   label: "Award Type",
    //   initialValue: awardType,
    //   required: true,
    //   select: true,
    //   disabled: id ? true : false,
    //   schema: () => Yup.string().nullable().required("Required"),
    //   options: [
    //     { label: "Contest Entries", value: "1" },
    //     { label: "Points", value: "2" },
    //     // { label: "Entries and Points", value: 2 },
    //   ],
    // },
    // {
    //   name: "recurring_engagement",
    //   label: "Interaction Type",
    //   initialValue: recurringEngagement,
    //   required: true,
    //   select: true,
    //   schema: () => Yup.string().nullable().required("Required"),
    //   options: [
    //     { label: "Recurring", value: "1" },
    //     { label: "Non-Recurring", value: "2" },
    //   ],
    // },
    {
      name: "microsite_gallery",
      label: "Include in gallery",
      initialValue: micrositeGallery,
      required: false,
      schema: () => null,
      toggle: true,
    },
  ]
    .filter((f) =>
      [2, 3].includes(variety) ? f.name !== "microsite_gallery" : true
    )
    .filter((f) =>
      wizardSettings.show_interaction_type === false
        ? f.name !== "recurring_engagement"
        : true
    )
    .filter((f) =>
      wizardSettings.show_award_type === false ? f.name !== "award_type" : true
    )
    .filter((f) => (!showShareData ? f.name !== "share_data" : true))
    .filter((f) =>
      !id
        ? ![
            "allow_manager_edit",
            "internal_name",
            "timezone",
            "third_party_campaign_code",
            "api_context",
            "api_program",
            "api_app_enum",
            "third_party_api_enabled",
            "send_registration_email",
            "pixel_code",
          ].includes(f.name)
        : !["variety"].includes(f.name)
    )
    .filter((f) =>
      isManager
        ? ![
            "allow_manager_edit",
            "third_party_api_enabled",
            "third_party_campaign_code",
            "api_context",
            "api_program",
            "api_app_enum",
            "send_from_email",
            "send_registration_email",
          ].includes(f.name)
        : true
    )
    .filter((f) =>
      !thirdPartyApiEnabled
        ? ![
            "api_context",
            "api_program",
            "third_party_campaign_code",
            "api_app_enum",
          ].includes(f.name)
        : true
    )
    .filter((f) =>
      wizardSetup
        ? ["start_date", "start_time", "end_date", "end_time"].includes(f.name)
        : true
    )
    .filter((f) =>
      id ? !["award_type", "recurring_engagement"].includes(f.name) : true
    );

  return (
    <BasicForm
      buttonProps={{
        fullWidth: !id ? true : false,
        // c: "green",
        // variant: "light",
      }}
      loading={loading}
      onSubmit={onSubmit}
      fields={fields}
    />
  );
}
