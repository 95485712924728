import axios from "axios";
import React, { useState, useEffect } from "react";
import { Link, useParams, Route, Routes } from "react-router-dom";
import { Modal, UnstyledButton, Title, Box } from "@mantine/core";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

import { ErrorView, PageTitle, Subnav } from "@components/shared";
import {
  UserContactManagement,
  UserForm,
  UserInteractions,
  UserJoinList,
  UserInteractionItem,
  UserMeta,
  UserLockCreate,
  UserLockManagement,
} from "@components/User";

import entityColor from "@util/entityColor";
import entityIcon from "@util/entityIcon";

export default function UserDetail() {
  const [info, setInfo] = useState(null);
  const [error, setError] = useState(null);
  const params = useParams();
  const { id } = params;

  const navigate = useNavigate();

  useEffect(() => {
    fetchData();
  }, []);

  function fetchData() {
    axios
      .get(`/users/${params.id}/`)
      .then(({ data }) => {
        setInfo(data.response[0]);
      })
      .catch((err) => {
        setInfo(null);
        setError(err);
      });
  }

  if (error) {
    return <ErrorView text={error} />;
  }

  if (!info) return null;

  return (
    <div>
      <PageTitle
        title={<React.Fragment>{info.anon_ident}</React.Fragment>}
        subtitle="User"
        icon={entityIcon.user()}
        iconColor={entityColor.user}
      />
      <Subnav
        buttonColor={entityColor.user}
        links={[
          { to: `/users/${id}`, text: "Detail " },
          { to: `/users/${id}/contacts`, text: "Contacts" },
          // { to: `/users/${id}/interactions`, text: "Interactions" },
        ]}
      />
      {info.user_lock ? (
        <Box mt="xl">
          <UserLockManagement lockData={info.user_lock} fetchData={fetchData} />
        </Box>
      ) : (
        <UserLockCreate userId={id} onSuccess={fetchData} />
      )}
      <Routes>
        <Route
          exact
          path="/"
          element={
            <React.Fragment>
              {/* <UserMeta data={info} /> */}
              <UserJoinList userId={id} />
              {/* <UserForm
                address1={info.address_1}
                address2={info.address_2}
                city={info.city}
                country={info.country}
                state={info.state_province}
                zip={info.zip}
                firstName={info.first_name}
                lastName={info.last_name}
                email={info.email}
                id={id}
                onSuccess={fetchData}
              /> */}
            </React.Fragment>
          }
        />
        <Route
          path="contacts"
          element={
            <React.Fragment>
              <UserContactManagement userId={id} />
            </React.Fragment>
          }
        />
        {/* <Route
          path="interactions"
          element={
            <React.Fragment>
              <UserJoinList userId={id} />
            </React.Fragment>
          }
        />
        <Route
          path="interactions/:interactionId"
          element={
            <React.Fragment>
              <UserInteractionItem
                onClose={() => navigate(`/users/${id}/interactions`)}
              />
              <UserInteractions userId={id} />
            </React.Fragment>
          }
        /> */}
      </Routes>
    </div>
  );
}
