import React, { createContext, useContext, useState, useEffect } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import { Button, Card, Grid, Group, Alert, Title } from "@mantine/core";
import { useQuery, useQueryClient } from "@tanstack/react-query";

import EffortBuilderForm from "./EffortBuilderForm";
import EffortFieldReorder from "./EffortFieldReorder";
import EffortFields from "./EffortFields";
import { PublishButton } from "@components/Effort/EffortStatus";
import { SaveFormButton } from "@components/Effort/EffortSavedForms";
import { generateEffortFieldRequest } from "./helpers";

const initialState = {
  description: "",
  fields: [],
  title: "",
};

export const Context = createContext(null);

export default function Provider({
  effortId,
  fetchEffortData,
  showPublish,
  effortName,
}) {
  const queryClient = useQueryClient();
  const [effortState, setEffortState] = useState(initialState);
  const [config, setConfig] = useState({});

  const queryKey = `effort${effortId}Configuration`;

  const fetchData = () => queryClient.invalidateQueries([queryKey]);

  const { failureReason, data: info } = useQuery({
    queryKey: [queryKey],
    queryFn: async () =>
      axios
        .get(`/efforts/${effortId}/configuration/`)
        .then(({ data }) => {
          const draftConfig = data.response[0];

          if (!draftConfig) return;

          setConfig(draftConfig);

          if (!draftConfig.fields || !draftConfig.fields.length) {
            setEffortState({
              title: "",
              description: "",
              fields: [],
            });
          } else {
            setEffortState({
              title: "",
              description: "",
              fields: draftConfig.fields,
            });
          }

          return data.response[0];
        })
        .catch((err) => {
          throw err;
        }),
  });

  // function fetchData() {
  //   axios
  //     .get(`/efforts/${effortId}/configuration/`)
  //     .then(({ data }) => {
  //       const draftConfig = data.response[0];

  //       if (!draftConfig) return;

  //       setConfig(draftConfig);

  //       if (!draftConfig.fields || !draftConfig.fields.length) {
  //         setEffortState({
  //           title: "",
  //           description: "",
  //           fields: [],
  //         });
  //       } else {
  //         setEffortState({
  //           title: "",
  //           description: "",
  //           fields: draftConfig.fields,
  //         });
  //       }
  //     })
  //     .catch((err) => {});
  // }

  function onUpdate(newState) {
    setEffortState({ ...effortState, ...newState });
    updateConfig({ ...effortState, ...newState });
  }

  function updateConfig(newConfig = effortState) {
    const req = {
      configuration: { ...config, ...newConfig },
    };

    axios
      .put(`/efforts/${effortId}/update-config/`, req)
      .then(() => {
        toast.success("Saved!");
        fetchEffortData();
        fetchData();
      })
      .catch((err) => {
        toast.error(err);
      });
  }

  return (
    <Context.Provider value={effortState}>
      <EffortBuilder
        effortId={effortId}
        effortName={effortName}
        onUpdate={onUpdate}
        showPublish={showPublish}
        updateConfig={updateConfig}
      />
    </Context.Provider>
  );
}

function EffortBuilder({
  effortId,
  effortName,
  onUpdate,
  showPublish,
  updateConfig,
}) {
  const { fields, title } = useContext(Context);

  function onFormSubmit(e) {
    generateEffortFieldRequest(
      fields,
      e,
      {
        first_name: "John",
        last_name: "Smith",
        id: "123-abc",
      },
      true
    ).then((newReq) => {
      console.log(newReq);
    });
  }

  return (
    <React.Fragment>
      <Grid>
        <Grid.Col span={{ base: 12, md: 6 }}>
          <div>
            <EffortFields
              effortId={effortId}
              onChange={(newFields) => onUpdate({ fields: newFields })}
              updateConfig={updateConfig}
            />
          </div>
          <Group mt="lg">
            <SaveFormButton effortName={effortName} fields={fields} />
            <EffortFieldReorder
              onUpdate={(newFields) => onUpdate({ fields: newFields })}
            />
            <Button onClick={() => updateConfig()} color="green">
              Save Work
            </Button>
            {showPublish && (
              <PublishButton
                buttonProps={{
                  size: "sm",
                  variant: "light",
                  color: "green",
                }}
                effortId={effortId}
                onSuccess={() => true}
              />
            )}
          </Group>
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 6 }}>
          <Title order={3} mb="sm">
            Preview
          </Title>
          <Card style={{ overflow: "visible" }}>
            <EffortBuilderForm
              fields={fields}
              onSubmit={(e) => {
                onFormSubmit(e);
              }}
            />
          </Card>
        </Grid.Col>
      </Grid>
    </React.Fragment>
  );
}
