import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import * as Yup from "yup";

import { BasicForm } from "@components/shared";
import countries from "@lib/countries";
import usStates from "@lib/usStates";
import timezones from "@lib/timezones";

export default function OrganizationForm({
  address1,
  address2,
  allowCampaignCreate = false,
  city,
  country = null,
  id = null,
  isProfile = false,
  keywordCode,
  manageLocations = false,
  name = "",
  onSuccess = () => {},
  otpDisplayName = "",
  state,
  timezone,
  thirdPartyIdent = "",
  zip,
}) {
  const [loading, setLoading] = useState(false);
  const isManager = useSelector((state) => state.manager) ? true : false;

  const navigate = useNavigate();

  function onSubmit(formData) {
    setLoading(true);

    if (id) return onUpdate(formData);

    return onCreate(formData);
  }

  function onCreate(formData) {
    axios
      .post(`/organizations/`, {
        ...formData,
        otp_display_name: formData.name.replace(/[0-9]/g, ""),
      })
      .then(({ data }) => {
        toast.success("Created");
        setLoading(false);
        navigate(`/organizations/${data.response[0].id}`);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  function onUpdate(formData) {
    const req = {
      ...formData,
      organization_id: id,
      state_province: formData.state,
    };

    axios
      .put(`/organizations/${id}/`, req)
      .then(() => setLoading(false))
      .then(() => toast.success("Updated!"))
      .then(() => onSuccess())
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  const fields = [
    {
      name: "name",
      label: "Name",
      initialValue: name,
      required: true,
      schema: () => Yup.string().required("Required"),
    },
    {
      name: "otp_display_name",
      label: "OTP Display Name (No numbers)",
      initialValue: otpDisplayName,
      required: true,
      schema: () =>
        Yup.string()
          .matches(/^([^0-9]*)$/, "No numbers")
          .required("Required"),
    },
    {
      name: "address_1",
      label: "Address",
      initialValue: address1,
      addressLookup: true,
      required: true,
      schema: () => Yup.string().required("Required!"),
    },
    {
      name: "address_2",
      label: "Address Line 2 (Ste, Apt, etc.)",
      initialValue: address2,
      schema: () => null,
    },
    {
      name: "city",
      label: "City",
      initialValue: city,
      required: true,
      schema: () => Yup.string().required("Required!"),
    },
    {
      name: "state",
      label: "State/Province",
      initialValue: state,
      select: true,
      options: usStates,
      required: true,
      schema: () => Yup.string().nullable().required("Required!"),
    },
    {
      name: "zip",
      label: "Zip/Postal Code",
      initialValue: zip,
      required: true,
      schema: () => Yup.string().required("Required!"),
    },
    {
      name: "country",
      label: "Country",
      initialValue: country,
      select: true,
      options: countries,
      required: true,
      schema: () => Yup.string().nullable().required("Required!"),
    },
    {
      name: "timezone",
      initialValue: timezone,
      required: true,
      select: true,
      schema: () => Yup.string("Required").nullable().required("Required"),
      label: "Timezone",
      options: timezones,
    },
    {
      name: "manage_locations",
      initialValue: manageLocations,
      required: false,
      toggle: true,
      schema: () => null,
      label: "Manage Locations",
    },
    {
      name: "allow_campaign_create",
      label: "Allow Campaign Create",
      initialValue: allowCampaignCreate,
      required: false,
      schema: () => null,
      toggle: true,
    },
    {
      name: "keyword_code",
      label: `Keyword Code (${keywordCodeHint})`,
      initialValue: keywordCode,
      disabled: isManager ? true : false,
      required: false,
      schema: () =>
        Yup.string()
          .matches(/(100\d|10[1-9]\d|1[1-9]\d{2}|[2-9]\d{3})/, keywordCodeHint)
          .matches(/^\d{4}$/, keywordCodeHint),
    },
    {
      name: "third_party_ident",
      label: "Third Party ID",
      initialValue: thirdPartyIdent,
      required: false,
      schema: () => Yup.string(),
    },
  ]
    .filter((f) =>
      !id ? ["name", "timezone", "keyword_code"].includes(f.name) : true
    )
    .filter((f) =>
      isManager
        ? !["allow_campaign_create", "third_party_ident"].includes(f.name)
        : true
    )
    .filter((f) => (isProfile ? !["manage_locations"].includes(f.name) : true));

  return (
    <BasicForm
      buttonProps={{
        fullWidth: !id ? true : false,
      }}
      loading={loading}
      onSubmit={onSubmit}
      fields={fields}
    />
  );
}

const keywordCodeHint = `4 digits ranging from 1000-9999`;
