import React, { Component } from "react";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import { Autocomplete, Loader } from "@mantine/core";

const service = new google.maps.places.AutocompleteService();

import { getUuid } from "@util/getUuid";

class AddressLookup extends Component {
  state = {
    init: false,
    results: [],
    searchValue: "",
    loading: false,
  };

  componentDidMount = () => {
    this.setState({ searchValue: this.props.address, init: true });
  };

  componentDidUpdate = (prevProps) => {
    // if (prevProps.address !== this.props.address && this.state.init) {
    //   this.setState({ searchValue: this.props.address });
    // }
  };

  onSearchChange = (e) => {
    this.props.onChange(e);
    this.setState({ searchValue: e }, () => this.fetchResults(e));
  };

  onAddressSelect = (description) => {
    this.setState({ loading: true });

    geocodeByAddress(description)
      .then((results) => {
        if (!results.length) {
          return;
        }

        return { ...results[0] };
      })
      .then((result) => {
        return getLatLng(result)
          .then((l) => {
            return {
              ...result,
              ...l,
            };
          })
          .catch((err) => {
            console.log("error");
          });
      })
      .then((resultData) => {
        const addressComponents = resultData.address_components;
        const addressData = {
          street_number: "",
          street_name: "",
          city: "",
          state: "",
          zip: "",
          country: "",
          // lat: resultData.lat,
          // lng: resultData.lng,
        };
        const keysToCheck = [
          { google_key: "street_number", address_key: "street_number" },
          { google_key: "route", address_key: "street_name" },
          { google_key: "locality", address_key: "city" },
          {
            google_key: "administrative_area_level_1",
            address_key: "state",
            use_short_name: true,
          },
          { google_key: "postal_code", address_key: "zip" },
          {
            google_key: "country",
            address_key: "country",
            use_short_name: true,
          },
        ];

        keysToCheck.forEach((k) => {
          const keyData = addressComponents.find((a) =>
            a.types.includes(k.google_key)
          );

          if (!keyData) {
            return;
          }

          addressData[k.address_key] =
            keyData[k.use_short_name ? "short_name" : "long_name"];
        });

        if (!addressData.city) {
          const sublocality = addressComponents.find((f) =>
            f.types.includes("sublocality")
          );
          if (!sublocality) return;
          addressData.city = sublocality.short_name;
        }

        return addressData;
      })
      .then((result) => {
        if (!result || !result.city) return;
        const allAddressKeys = {
          ...defaultAddressKeys,
          ...this.props.addressKeys,
        };
        this.props.onSelect({
          [allAddressKeys.address_1]: `${result.street_number} ${result.street_name}`,
          [allAddressKeys.city]: result.city,
          [allAddressKeys.state]: result.state,
          [allAddressKeys.zip]: result.zip,
          country: result.country,
        });
        this.setState({
          loading: false,
          searchValue: `${result.street_number} ${result.street_name}`,
        });
      });
  };

  fetchResults = (address) => {
    service.getPlacePredictions(
      { input: address, componentRestrictions: { country: ["us", "ca"] } },
      (results, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          this.setState({
            error: null,
            loading: false,
            results: results.map((r) => ({
              ...r,
              key: getUuid(),
              label: r.description,
              value: r.description,
            })),
          });
        } else {
          this.setState({ loading: false, results: [] });
        }
      }
    );
  };

  render() {
    if (!this.state.init) return null;

    return (
      <Autocomplete
        data={this.state.results}
        error={this.props.errorText}
        label={this.props.label}
        onChange={(e) => this.onSearchChange(e)}
        onOptionSubmit={(e) => this.onAddressSelect(e)}
        placeholder="Start typing..."
        required={this.props.required}
        rightSection={this.state.loading ? <Loader size="xs" /> : null}
        value={this.state.searchValue}
      />
    );
  }
}

const defaultAddressKeys = {
  address_1: "address_1",
  address_2: "address_2",
  city: "city",
  state: "state",
  zip: "zip",
};

export default AddressLookup;

AddressLookup.defaultProps = {
  address: "",
  addressKeys: defaultAddressKeys,
  error: false,
  errorText: "",
  label: "Address",
  required: true,
};
