import React, { useState } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import * as Yup from "yup";
import { BasicForm } from "@components/shared";

export default function ArtistForm({
  id = null,
  name = "",
  onSuccess = () => {},
  isCuration,
}) {
  const [loading, setLoading] = useState(false);

  function onSubmit(formData) {
    setLoading(true);

    onUpdate(formData);
  }

  function onUpdate(formData) {
    const req = {
      artists: [
        {
          ...formData,
          artist_id: id,
        },
      ],
    };

    axios
      .put(`/artists/${id}/`, req)
      .then(() => setLoading(false))
      .then(() => toast.success("Updated!"))
      .then(() => onSuccess())
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  const fields = [
    {
      name: "name",
      label: "Name",
      initialValue: name,
      required: true,
      schema: () => Yup.string().required("Required"),
    },
  ];

  return (
    <BasicForm
      loading={loading}
      onSubmit={onSubmit}
      fields={fields}
      buttonProps={{
        fullWidth: isCuration ? true : false,
      }}
    />
  );
}
