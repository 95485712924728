import React, { useState, useEffect } from "react";
import axios from "axios";
import { Modal, Card, Button, Switch, Group } from "@mantine/core";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";

import { ErrorView, AvatarManagement, PageTitle } from "@components/shared";
import { AdminForm } from "@components/Admin";
import { PasswordForm } from "@components/Auth";

import entityIcon from "@util/entityIcon";
import entityColor from "@util/entityColor";

export default function AdminProfile({ isProfile }) {
  const [error, setError] = useState(null);
  const [admin, setAdmin] = useState(populateAdmin());
  const params = useParams();
  const { id } = params;

  const adminId = isProfile ? useSelector((state) => state.admin).admin_id : id;

  useEffect(() => {
    fetchData();
  }, []);

  function fetchData() {
    axios
      .get(`/admins/${adminId}/`)
      .then(({ data }) => {
        setAdmin(populateAdmin(data.response[0]));
      })
      .catch((err) => {
        setError(err);
        setAdmin(null);
      });
  }

  if (error) {
    return <ErrorView text={error} />;
  }

  if (!admin) return null;

  return (
    <div>
      <Group mb="lg">
        <AvatarManagement asset={admin.profile_asset} adminId={adminId} />
        <PageTitle
          title={`${admin.first_name} ${admin.last_name}`}
          subtitle={"Admin"}
          icon={entityIcon.admin()}
          iconColor={entityColor.admin}
        />
      </Group>
      <Card>
        <AdminForm
          id={adminId}
          firstName={admin.first_name}
          lastName={admin.last_name}
          email={admin.email}
          mfa={admin.mfa}
          onSuccess={fetchData}
        />
      </Card>
      {isProfile && <ChangePassword adminId={adminId} />}
      {!isProfile && (
        <AdminStatus
          adminId={adminId}
          fetchData={fetchData}
          status={admin.status}
        />
      )}
    </div>
  );
}

function populateAdmin(d = {}) {
  return {
    first_name: d.first_name || "",
    email: d.email || "",
    last_name: d.last_name || "",
    mfa: d.mfa || false,
    profile_asset: d.profile_asset || null,
    status: d.status || null,
  };
}

function ChangePassword({ adminId }) {
  const [isOpen, setOpen] = useState(false);

  return (
    <React.Fragment>
      <Button variant="light" mt="xl" onClick={() => setOpen(true)}>
        Change Password
      </Button>
      <Modal
        opened={isOpen}
        onClose={() => setOpen(false)}
        title="Change Password"
      >
        <PasswordForm
          onSuccess={() => setOpen(false)}
          updating
          additionalReqData={{
            admin_id: adminId,
          }}
        />
      </Modal>
    </React.Fragment>
  );
}

function AdminStatus({ adminId, fetchData, status }) {
  function changeStatus() {
    axios
      .get(`/admins/${adminId}/change-status/`)
      .then(() => {
        fetchData();
      })
      .catch((err) => {
        toast.error(err);
        fetchData();
      });
  }

  return (
    <React.Fragment>
      <Switch
        mt="xl"
        size="md"
        description={
          status === 2
            ? "Unchecking will deactivate this admin preventing them from logging in."
            : "Checking will reactivate this admin allowing them to log in."
        }
        label="Activation Status"
        onClick={() => changeStatus()}
        checked={status === 2}
      />
    </React.Fragment>
  );
}
