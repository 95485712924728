import React, { useState, useEffect } from "react";
import { shallow } from "zustand/shallow";
import { useNavigate } from "react-router-dom";
import { Button, TextInput, Space } from "@mantine/core";
import axios from "axios";
import toast from "react-hot-toast";

import { CustomTooltip } from "@components/shared";
import Intervals from "@components/Keyword/KeywordSettings/Intervals";
import { KeywordForm } from "@components/Keyword";
import { initialState as initialKeywordSettings } from "@components/Keyword/KeywordSettings/helpers";
import {
  initialState as effortInitialSettings,
  generateEffortRebounds,
} from "@components/Effort/EffortSettings/helpers";
import {
  OptionList,
  SectionTitle,
  MembersOnlyQuestion,
  MicrositeQuestion,
} from "./WizardComponents";
import {
  defaultEffortConfig,
  effortPointSettings,
  effortWizardSettings,
} from "@components/Effort/helpers";
import { initialConfig as initialMicrositeConfig } from "@components/shared/MicrositeSettings/helpers";
import { keywordWizardSettings } from "@components/Keyword/helpers";

import useAppStore from "./wizard-store";

export default function WizardPoints() {
  const [loading, setLoading] = useState(false);
  const [interactionType, setInteractionType] = useState(null);
  const [intervals, setIntervals] = useState([]);

  const navigate = useNavigate();

  const {
    contestSettings,
    location,
    memberOnly,
    microsite,
    organization,
    recurring,
    setContestSettings,
    setRecurring,
  } = useAppStore(
    (state) => ({
      contestSettings: state.contestSettings,
      location: state.location,
      memberOnly: state.member_only,
      microsite: state.microsite,
      organization: state.organization,
      recurring: state.recurring,
      setContestSettings: state.setContestSettings,
      setRecurring: state.setRecurring,
    }),
    shallow
  );

  useEffect(() => {
    if (organization) {
      // setInteractionType(2);
    }
  }, [organization]);

  function onSubmit() {
    createCheckin();
  }

  function createCheckin() {
    const req = {
      name: contestSettings.name,
      location_id: location ? location.id : null,
      organization_id: organization ? organization.id : null,
      award_points: true,
    };
  }

  function generateKeywordData() {
    return {
      award_points: true,
      location_id: location ? location.id : null,
      organization_id: organization ? organization.id : null,
      settings: {
        ...initialKeywordSettings,
        intervals,
        members_only: memberOnly,
        point_value: 15,
      },
      wizard_settings: {
        ...keywordWizardSettings,
        claim_interval_required: true,
        point_value_required: true,
        show_contests: false,
        show_text: true,
        time_interval_required: true,
      },
      create_effort: {
        award_type: 2,
        location_id: location ? location.id : null,
        organization_id: organization ? organization.id : null,
        recurring_engagement: 2,
        title: `${contestSettings.name} Points Keyword Effort`,
        variety: 4,
        points_settings: {
          ...effortPointSettings,
          value: 15,
        },
        draft_app_configuration: initialMicrositeConfig,
        draft_configuration: defaultEffortConfig,
        settings: {
          ...effortInitialSettings,
          ...generateEffortRebounds(contestSettings.name),
        },
        wizard_settings: {
          ...effortWizardSettings,
          end_required: true,
          keyword_for_points: true,
          keyword_sms_required: true,
          locations_required: false,
          // locations_required: organization ? true : false,
          member_only: memberOnly,
          microsite: false,
          point_award_required: true,
          recurring: false,
          registration_method: "keyword",
          return_points_keyword: true,
          show_assets: false,
          show_award_type: false,
          show_awards: true,
          show_builder: false,
          show_csv: false,
          show_geospots: false,
          show_interaction_type: false,
          show_keywords: true,
          show_look_feel: false,
          show_quick_settings: false,
          show_settings: false,
          start_required: true,
          use_keywords: true,
        },
      },
    };
  }

  function onFormSubmit() {
    setLoading(true);

    const req = {
      create_effort: {
        title: contestSettings.name,
        location_id: location ? location.id : null,
        organization_id: organization ? organization.id : null,
        recurring_engagement: 2,
        award_type: 2,
        variety: 4,
        points_settings: {
          ...effortPointSettings,
          value: 25,
        },
        draft_app_configuration: initialMicrositeConfig,
        draft_configuration: defaultEffortConfig,
        wizard_settings: {
          ...effortWizardSettings,
          // locations_required: organization ? true : false,
          // look_and_feel_required: memberOnly ? false : true,
          // microsite: false,
          builder_required: true,
          contest_required: false,
          end_required: true,
          featured_image_required: true,
          locations_required: false,
          look_and_feel_required: microsite ? true : false,
          member_only: memberOnly,
          microsite: microsite,
          point_award_required: true,
          recurring: false,
          registration_method: "form",
          show_awards: true,
          show_builder: true,
          show_csv: true,
          show_geospots: false,
          show_keywords: false,
          show_look_feel: microsite ? true : false,
          show_settings: false,
          start_required: true,
          use_keywords: false,
        },
      },
    };

    axios
      .post(`/wizard/`, req)
      .then(({ data }) => {
        navigate(`/efforts/${data.response[0].campaign_effort_id}`);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  const additionalKeywordData = generateKeywordData();
  const submitDisabled = !contestSettings.name || (!location && !organization);
  const showEarnQuestion =
    organization || (location && location.variety === 1) ? true : false;

  return (
    <div>
      <Space mt="lg" />
      {showEarnQuestion && (
        <React.Fragment>
          <SectionTitle text="How will users earn points?" />
          <OptionList
            options={[
              // { label: "Check-in", value: 1 },
              { label: "Keyword", value: 2 },
              { label: "Survey", value: 3 },
            ]
              .filter((f) => (organization ? f.value !== 1 : true))
              .map((m) => ({
                ...m,
                active: interactionType === m.value,
                onChange: () => setInteractionType(m.value),
              }))}
          />
        </React.Fragment>
      )}
      {/* <SectionTitle text="Is this recurring?" />
      <OptionList
        options={[
          { label: "Yes", value: true },
          { label: "No", value: false },
        ].map((opt) => ({
          label: opt.label,
          onChange: () => setRecurring(opt.value),
          active: recurring === opt.value,
        }))}
      /> */}
      {interactionType !== null && <MembersOnlyQuestion />}
      {interactionType === 1 && (
        <React.Fragment>
          <p>Coming soon</p>
        </React.Fragment>
      )}
      {interactionType === 2 && memberOnly !== null && (
        <React.Fragment>
          {/* <Intervals
            title="Keyword Intervals"
            sectionTitle="Set up keyword intervals (optional)"
            items={intervals}
            onChange={(e) => {
              setIntervals(e);
            }}
          /> */}
          <CustomTooltip label="Keyword names will be visible to the users.">
            <SectionTitle text="Tell us a little more" />
          </CustomTooltip>
          <KeywordForm
            additionalReqData={additionalKeywordData}
            wizard
            locationId={location ? location.id : null}
            organizationId={organization ? organization.id : null}
            onSuccess={(createdEffortId) =>
              navigate(`/efforts/${createdEffortId}`)
            }
            smsRequired
            // onSuccess={(createdKeywordId) =>
            //   navigate(`/keywords/${createdKeywordId}`)
            // }
          />
        </React.Fragment>
      )}
      {interactionType === 3 && (
        <React.Fragment>
          {memberOnly === true && <MicrositeQuestion />}
          {microsite !== null && (
            <>
              <CustomTooltip label="Titles will be visible to the users.">
                <SectionTitle text="Tell us a little more" />
              </CustomTooltip>
              <TextInput
                label={"Title"}
                value={contestSettings.name}
                onChange={(e) =>
                  setContestSettings({
                    ...contestSettings,
                    name: e.target.value,
                  })
                }
              />
              <Button
                mt="sm"
                fullWidth
                onClick={onFormSubmit}
                loading={loading}
                disabled={submitDisabled}
              >
                Create
              </Button>
            </>
          )}
        </React.Fragment>
      )}
      {/* {interactionType && (
        <React.Fragment>
          <Space mt="lg" />
          <SectionTitle text="Tell us a little more" />
          <TextInput
            label={interactionType === 1 ? "Name" : "Keyword"}
            value={contestSettings.name}
            onChange={(e) =>
              setContestSettings({
                ...contestSettings,
                name: e.target.value,
              })
            }
          />
          <Button
            mt="lg"
            fullWidth
            onClick={onSubmit}
            loading={loading}
            disabled={submitDisabled}
          >
            Create
          </Button>
        </React.Fragment>
      )} */}
    </div>
  );
}
