import axios from "axios";
import React, { useState, useEffect } from "react";
import { Link, useParams, Route, Routes } from "react-router-dom";
import {
  Anchor,
  Flex,
  Button,
  Card,
  Grid,
  Space,
  Switch,
  Text,
} from "@mantine/core";
import { useSelector } from "react-redux";

import { ErrorView, PageTitle, Subnav } from "@components/shared";
import { AuctionBidManagement } from "@components/Auction";
import {
  ContestAlerts,
  ContestAssets,
  ContestKeywordManagement,
  ContestReadOnly,
  ContestStatus,
  ContestWizardStatus,
} from "@components/Contest";
import { TagManagement } from "@components/Tag";
import { PrizeManagement } from "@components/Prize";
import { EntityEmailTemplates } from "@components/EmailServer";
import { PrizePoolForm } from "@components/PrizePool";
import { LocationAssociate } from "@components/Location";

import entityColor from "@util/entityColor";
import entityIcon from "@util/entityIcon";
import { penniesToDollars } from "@util/formatters";

export default function AuctionDetail() {
  const [info, setInfo] = useState(null);
  const [error, setError] = useState(null);
  const [showWizard, setShowWizard] = useState(true);
  const params = useParams();
  const { id } = params;

  const managerInfo = useSelector((state) => state.manager);
  const isManager = managerInfo ? true : false;

  useEffect(() => {
    setInfo(null);
    fetchData();
  }, [id]);

  function fetchData() {
    axios
      .get(`/contests/${params.id}/`)
      .then(({ data }) => {
        setInfo(data.response[0]);
      })
      .catch((err) => {
        setInfo(null);
        setError(err);
      });
  }

  if (error) {
    return <ErrorView text={error} />;
  }

  if (!info) return null;

  const subtitles = [
    {
      value: info.created_in_campaign_effort_id,
      icon: entityIcon.effort(),
      color: entityColor.effort,
      name: info.created_in_name,
      url: `/efforts`,
    },
    {
      value: info.organization_id,
      icon: entityIcon.organization(),
      color: entityColor.organization,
      name: info.organization_name,
      url: `/organizations`,
    },
    {
      value: info.location_id,
      icon: entityIcon.location(),
      color: entityColor.location,
      name: info.location_name,
      url: `/locations`,
    },
  ]
    .filter((f) => f.value)
    .filter((f) =>
      managerInfo ? ![`/locations`, `/organizations`].includes(f.url) : true
    )
    .map((m) => ({
      ...m,
      url: `${m.url}/${m.value}`,
    }));

  const { wizard_settings: wizardSettings, wizard_status: wizardStatus } = info;
  const wizardComplete = wizardStatus.wizard_complete;
  const showLocations =
    (!managerInfo ||
      (managerInfo && managerInfo.organization_id) ||
      info.organization_id) &&
    info.created_in_campaign_effort_id;

  const ContestTitle = () => (
    <>
      <PageTitle
        title={info.name}
        subtitle={<React.Fragment>Auction</React.Fragment>}
        icon={entityIcon.contest()}
        iconColor={entityColor.contest}
        style={{ marginBottom: 0 }}
      />
      {true && (
        <React.Fragment>
          {subtitles.map((m, i) => (
            <PageTitle
              key={i}
              icon={m.icon}
              iconColor={m.color}
              style={{ marginBottom: 0 }}
              subtitle={
                <Anchor
                  style={{
                    color: "inherit",
                  }}
                  component={Link}
                  to={m.url}
                  target="_blank"
                >
                  {m.name}
                </Anchor>
              }
            />
          ))}
        </React.Fragment>
      )}
      <Space mt="lg" />
    </>
  );

  const WizardToggle = () => (
    <Switch
      label="Quick Settings"
      checked={showWizard}
      onChange={() => setShowWizard(!showWizard)}
      mb="lg"
    />
  );

  if (!info.edit) {
    return (
      <React.Fragment>
        <ContestTitle />
        <ContestReadOnly info={info} />
      </React.Fragment>
    );
  }

  if (!wizardComplete) {
    return (
      <div>
        <ContestTitle />
        {/* {wizardComplete && <WizardToggle />} */}
        <ContestWizardStatus
          effortId={
            info.created_in_campaign_effort_id || info.campaign_effort_id
          }
          isAuction
          fetchData={fetchData}
          id={id}
          locationId={info.location_id}
          organizationId={info.organization_id}
          status={info.status}
          wizardComplete={wizardComplete}
          wizardSettings={wizardSettings}
          wizardStatus={wizardStatus}
          prize={info.prizes[0]}
          contestDates={
            info.start_date && info.end_date
              ? {
                  start_date: info.start_date,
                  start_time: info.start_time,
                  start_date_formatted: info.start_date_formatted,
                  end_date: info.end_date,
                  end_date_formatted: info.end_date_formatted,
                  end_time: info.end_time,
                }
              : null
          }
        />
      </div>
    );
  }

  return (
    <div>
      <ContestTitle />
      <Text mb="sm" size="sm">
        This Auction is{" "}
        <b>
          {info.status === 1 ? "waiting" : info.status === 2 ? "on" : "off"}
        </b>
      </Text>
      {/* {wizardComplete && <WizardToggle />} */}
      {/* <Space mt="lg" /> */}
      <Flex>
        <Subnav
          buttonColor={entityColor.contest}
          links={[
            { to: `/auctions/${id}`, text: "Detail" },
            { to: `/auctions/${id}/assets`, text: "Image" },
            { to: `/auctions/${id}/tags`, text: "Tags" },
            { to: `/auctions/${id}/bid-history`, text: "Bid History" },
            { to: `/auctions/${id}/prizes`, text: "Prize" },
            { to: `/auctions/${id}/email`, text: "Email" },
            // { to: `/auctions/${id}/locations`, text: "Locations" },
          ].filter((f) => (!showLocations ? f.text !== "Locations" : true))}
        />
        {info.created_in_campaign_effort_id && info.organization_id && (
          <Button
            component={Link}
            variant="subtle"
            color="gray"
            size="xs"
            target="_blank"
            to={`/efforts/${info.created_in_campaign_effort_id}/locations`}
          >
            Locations
          </Button>
        )}
      </Flex>
      {/* <Space mt="lg" /> */}
      {wizardComplete && (
        <ContestAlerts contestId={id} setShowWizard={(e) => setShowWizard(e)} />
      )}
      <Routes>
        <Route
          exact
          path="/"
          element={
            <React.Fragment>
              <Grid>
                <Grid.Col span={{ base: 12, md: 12 }}>
                  <Card>
                    <PrizePoolForm
                      allowLocationAccess={info.allow_location_access}
                      contactName={info.contact_name}
                      contactNumber={info.contact_number}
                      contactEmail={info.contact_email}
                      endDate={info.end_date}
                      endTime={info.end_time}
                      id={id}
                      isManager={isManager}
                      name={info.name}
                      onSuccess={fetchData}
                      pickupDate={info.pickup_date}
                      pickupTime={info.pickup_time}
                      pickupTimezone={info.pickup_timezone}
                      retailValue={
                        info.retail_value_in_pennies
                          ? penniesToDollars(info.retail_value_in_pennies)
                          : ""
                      }
                      startDate={info.start_date}
                      startTime={info.start_time}
                      timezone={info.timezone}
                    />
                  </Card>
                </Grid.Col>
                {/* <Grid.Col span={{ base: 12, md: 3 }}>
                  <ContestStatus
                    contestId={id}
                    status={info.status}
                    fetchData={fetchData}
                    liveStatus={info.live_status}
                    isAuction
                  />
                </Grid.Col> */}
              </Grid>
            </React.Fragment>
          }
        />
        <Route
          path="keywords"
          element={
            <React.Fragment>
              <ContestKeywordManagement contestId={id} />
            </React.Fragment>
          }
        />
        <Route
          path="prizes"
          element={
            <React.Fragment>
              <PrizeManagement
                organizationId={info.organization_id}
                locationId={info.location_id}
                contestId={id}
                fetchData={fetchData}
                isAuction
              />
            </React.Fragment>
          }
        />
        {/* <Route
          path="participants"
          element={
            <React.Fragment>
              <ContestParticipants
                contestId={id}
                orgId={info.organization_id}
                locationId={info.location_id}
              />
            </React.Fragment>
          }
        /> */}
        {/* <Route
          path="settings"
          element={
            <React.Fragment>
              <ContestSettings
                contestId={id}
                beginDate={info.start_date}
                endDate={info.end_date}
                contestInfo={info}
                fetchContest={fetchData}
                canEditWinnerRules={info.can_edit_winner_rules}
              />
            </React.Fragment>
          }
        /> */}
        <Route
          path="tags"
          element={
            <React.Fragment>
              <TagManagement contestId={id} />
            </React.Fragment>
          }
        />
        <Route
          path="assets"
          element={
            <React.Fragment>
              <ContestAssets
                contestId={id}
                contestInfo={info}
                fetchContest={fetchData}
              />
            </React.Fragment>
          }
        />
        <Route
          path="bid-history"
          element={
            <React.Fragment>
              <AuctionBidManagement contestId={id} fetchData={fetchData} />
            </React.Fragment>
          }
        />
        <Route
          path="email"
          element={
            <React.Fragment>
              <EntityEmailTemplates
                postmarkTemplateNames={info.postmark_template_names}
                contestId={id}
                templates={[
                  {
                    name: "Winner Notification",
                    field_name: "winner-notification",
                    value: info.winner_template,
                  },
                ]}
                fetchData={fetchData}
              />
            </React.Fragment>
          }
        />
        {showLocations && (
          <Route
            exact
            path="locations"
            element={
              <React.Fragment>
                <LocationAssociate
                  effortId={info.created_in_campaign_effort_id}
                />
              </React.Fragment>
            }
          />
        )}
      </Routes>
    </div>
  );
}
